@import 'variables.scss';

$input-height: 3.75rem;

.passwordInput {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .label {
    margin: 0;
    font-weight: 500;
  }

  .inputContainer {
    border-radius: $border-radius;
    overflow: hidden;
    input {
      height: $input-height;
      width: 100%;
      color: #51588c;
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      padding: 1.125rem;
      padding-left: 1.25rem;
      border: none;
      text-overflow: ellipsis;
      outline: none;
      background: $c-mirage;
      transition: all 0.1s ease-in-out;
      border-bottom: 2px solid transparent;

      &:focus {
        border-bottom: 2px solid $c-titan-white;
      }
    }

    .button {
      position: absolute;
      top: 50%;
      right: 0.625rem;
      transform: translateY(-50%);
      padding: 0.625rem;
      border-radius: $border-radius;
      @include flex-center-center;
      color: $c-titan-white;
      cursor: pointer;
      user-select: none;
      transition: $basic-transition;

      &:hover {
        background-color: rgba($c-titan-white, 0.05);
      }
    }
  }
}
