@import 'variables.scss';

.wrapper {
  display: flex;
  gap: 4.375rem;
  height: 100%;
  overflow: hidden;
  color: $c-titan-white;

  .donut {
    position: relative;
    z-index: 1;
    max-width: 12.8125rem;
    width: 100%;
    align-self: center;

    .lastUpdate {
      font-size: 0.75rem;
      color: $c-scampi;
      margin-top: 0.9375rem;
      text-align: center;
    }
    .networkInfo {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .networkIcon {
        background-color: $c-mirage;
        width: 2rem;
        height: 2rem;
        border-radius: $border-radius;
      }

      .networkAmount {
        font-size: 1.875rem;
        font-weight: 400;
        color: $c-titan-white;

        .currency {
          color: $c-turquoise;
        }
      }
    }
  }

  .legend {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .legendTitle {
      font-size: 1.5625rem;
      font-weight: 400;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    .legendItems {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 0.9375rem;
      overflow-y: auto;
      scrollbar-width: thin;

      .item {
        display: flex;
        align-items: center;
        gap: 1.125rem;
        padding: 0.4375rem 0.3125rem;
        border-radius: 0.5rem;
        border: 1px solid transparent;
        transition: 0.3s ease-out border;
        height: 2.25rem;

        &:last-child {
          border-bottom: none;
        }

        &.active {
          background-color: $c-ebony-clay-light;
          border: 1px solid $c-fiord;
        }

        .separator {
          flex: 1 0 auto;
        }

        .color {
          flex: 0 0 auto;
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.5rem;
        }

        label {
          flex: 0 0 auto;
          font-size: 0.875rem;
          color: $c-titan-white;
        }

        .percent {
          flex: 0 0 auto;
          font-size: 0.875rem;
          color: $c-titan-white;
        }
      }
    }
    .noTokensWrapper {
      display: flex;
      flex: 1;
      align-items: center;
      .noTokens {
        display: flex;
        gap: 0.625rem;
        max-width: 15.625rem;

        label {
          flex: 1;
        }

        svg {
          margin-top: 0.3125rem;
        }
      }
    }
  }
}

@include xxl-breakpoint {
  .wrapper {
    gap: 2.5rem;

    .legend {
      .legendTitle {
        font-size: 1.25rem;
      }
    }
  }
}

@include xl-breakpoint {
  .wrapper {
    .legend {
      .legendItems {
        .item {
          label,
          .percent {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    .donut {
      height: auto;

      .networkInfo {
        .networkIcon {
          width: 1.75rem;
          height: 1.75rem;
        }

        .networkAmount {
          font-size: 1.5rem;
        }
      }
    }

    .noTokensWrapper {
      padding: 1.25rem;
      background: $c-ebony-clay;
      border: 1px solid $c-fiord;
      border-radius: $border-radius;
    }

    .legend {
      .legendTitle {
        margin-left: 1.25rem;
        margin-bottom: 0.9375rem;
      }
      .legendItems {
        padding: 0.625rem 1.25rem;
        background: $c-ebony-clay;
        border: 1px solid $c-fiord;
        border-radius: $border-radius;
        overflow-y: auto;
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .donut {
      .lastUpdate {
        font-size: 0.875rem;
      }
    }
  }
}
