@import 'variables.scss';

$max-modal-height: 51.25rem;
$min-modal-height: 34.375rem;

.wrapper {
  z-index: 52;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.875rem;
  max-height: $max-modal-height;
  min-height: $min-modal-height;
  max-width: 36.25rem;
  width: 100%;
  padding: 1.875rem;
  border: 1px solid $c-fiord;
  background: $c-ebony-clay;
  border-radius: $border-radius;

  &.sm {
    max-width: 31.25rem;
  }

  &.lg {
    max-width: 42.5rem;
  }

  .heading {
    display: flex;
    justify-content: space-between;

    .title {
      margin: 0;
      font-size: 1.5625rem;
      font-weight: 400;

      &.centered {
        text-align: center;
        margin: 0 auto;
      }
    }

    .headingIcon {
      cursor: pointer;

      &:hover {
        opacity: 0.2;
      }
    }
  }

  .content {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    height: 100%;
    padding: 0 0.625rem; // fixes scrollbar being shoved into the content
    flex: 1;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    button {
      flex: 1;
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    padding: 1.875rem;
  }
}

@media screen and (max-height: calc(#{$max-modal-height} + 100px)) {
  .wrapper {
    max-height: 100%;
  }
}

@media screen and (max-height: calc(#{$min-modal-height} + 100px)) {
  .wrapper {
    min-height: 100%;
  }
}

@include sm-breakpoint {
  .wrapper {
    padding: 1.25rem 0;

    // Fixes overflow issues on mobile
    .heading,
    .content,
    .buttons {
      padding: 0 1.25rem;
    }
  }
}
