@import 'variables.scss';

.purpleHighlight {
  color: $c-heliotrope;
}

.balanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 2.25rem;
  border: 1px solid transparent;
  border-bottom-color: $c-fiord;
  padding: 0.0625rem 1.25rem;
  padding-left: 0;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:last-child {
    border-bottom: none;
  }

  label {
    opacity: 1;
    cursor: pointer;
    color: $c-titan-white;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .network {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-weight: 400;
  }
}

@include xl-breakpoint {
  .balanceContainer {
    label {
      font-size: 1rem;
    }
  }
}

@include lg-breakpoint {
  .balanceContainer {
    height: auto;
    padding-right: 0;
  }
}
