@import 'variables.scss';

.placeholderOverlay {
  z-index: 4;
  @include absolute-full-size;
  @include flex-center-center;

  .placeholderText {
    text-align: center;
    text-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.3);

    h2 {
      font-size: 1.375rem;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    p {
      margin: 0;
      cursor: pointer;
    }
  }
}

.blur {
  filter: blur(0.9375rem);
  opacity: 0.4;
  padding-bottom: 3.125rem;
}

@include sm-breakpoint {
  .placeholderOverlay {
    align-items: flex-start;
    padding-top: 6.25rem;
  }
}
