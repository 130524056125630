@import '../../../variables';

#plugin-gnosis-container {
  position: relative;
  height: 100%;
  @include flex-full-grow-container;

  iframe {
    @include flex-full-grow-container;
    overflow: auto;
    box-sizing: border-box;
    border: 0;
  }

  .iframe-placeholder {
    @include flex-full-grow-container;
  }

  .iframe-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  #dapps-container {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -1rem 0 -1rem;

    li {
      margin: 0.5rem 1rem;
      flex: 1 0 7.5rem;
      list-style: none;
      background: $c-ebony-clay;
      padding: 1rem;
      cursor: pointer;
      transition: $basic-transition;

      &:hover {
        background: lighten($c-ebony-clay, 5%);
      }

      &.selected {
        background: lighten($c-ebony-clay, 50%);
      }

      &.add-plugin {
        align-self: center;
        justify-content: center;
        text-align: center;
        background: 0;
        cursor: default;
      }

      div.logo-container {
        margin: 0.75rem auto 0.5rem auto;
        width: 3rem;
        height: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
      }

      div.dapp-name {
        text-align: center;
        font-size: 2em;
        padding-bottom: 1rem;
      }

      div.dapp-desc {
        min-width: 0;
        overflow: hidden;
        text-align: center;
        // text-overflow-ellipsis: "...";
        text-overflow: ellipsis'...';
        font-size: 0.9em;
        line-height: 1.4em;
        color: $text-container-color;
      }
    }

    &.small-thumbs {
      margin: 0 -0.1875rem 0.375rem -0.1875rem;

      li {
        margin: 0.1875rem 0.1875rem;
        padding: 0.375rem;
        position: relative;

        .remove-btn {
          position: absolute;
          top: 0;
          right: 0;
        }

        div.logo-container {
          margin: 0.25rem auto 0.25rem auto;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.25rem;
        }

        div.dapp-name {
          text-align: center;
          font-size: 0.8em;
          padding-bottom: 0.375rem;
        }

        div.dapp-desc {
          min-width: 0;
          display: none;
        }
      }
    }
  }
}
