@import 'variables.scss';

.loginSignupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.125rem;
  min-height: 100%;
  padding: 2.5rem 0;
  overflow: hidden;

  &.dark {
    background: url('/resources/login-bg.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    input:focus {
      border-bottom-color: $c-white;
    }
  }

  h3 {
    font-size: 1.5625rem;
    text-align: left;
    font-weight: 400;
    margin-bottom: 2.6875rem;
  }

  button:not([type='submit']),
  input {
    font-size: 1rem;
    border: 0;
    background: none; /* #282c34; */
    padding: 1rem;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    transition: all 0.1s ease-in-out;
  }

  .resendBtn {
    max-width: 250px;
    margin: 0 auto;
  }

  button {
    background-color: white;
  }

  input:focus {
    outline: 0;
  }

  button:not([type='submit']) {
    opacity: 0.8;
  }

  button:not([type='submit']):not([disabled]):hover {
    opacity: 1;
  }
  button[type='submit'] {
    width: 100%;
  }

  input:disabled,
  button:disabled {
    opacity: 0.5 !important;
    cursor: default;
  }

  input {
    color: $c-scampi;
    margin: 2rem 0;
    border-bottom: 2px solid theme(border-input);
    width: 100%;
    font-size: 1rem;

    &:focus {
      border-bottom-color: $c-electric-violet;
    }
  }

  .logo {
    width: 100%;
    max-width: 15.75rem;
    margin: 0 auto;
    height: 6.875rem;
  }

  .pluginInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.2rem;

    .name {
      font-size: 1.7em;
    }
  }

  .signersToChoose {
    margin: 0 auto;
    display: block;
    line-height: 2em;
    font-size: 1.5rem;
    color: #ccc;
    list-style: none;
    font-family: 'Ubuntu Mono';
    width: 31.25rem;
    cursor: pointer;

    li:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: $c-white;
    }
  }

  .loginEmail {
    flex: 0.9;
  }

  .loginEmail form > input,
  .addAccount form > input {
    display: block;
  }

  .addAccount {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 50rem;
    /* TODO some sort of vertical alignment */
    margin: 0em auto 0em;

    .loginSeparator {
      width: 0.0625rem;
      // background-color: theme(background-fournary);
      background-color: $c-scampi;
      margin: 0 6rem;
      margin-top: 2.1875rem;
      margin-bottom: 1.875rem;
    }

    .loginOthers {
      flex: 0.9;

      button {
        position: relative;
        top: 0;
        width: 100%;
        background: theme(background-primary);
        border: 1px solid $c-scampi;
        border-radius: $border-radius;
        color: theme(text-secondary);
        height: 3.75rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4375rem;
        margin-bottom: 1.25rem;
        transition: 0.3s ease-out all;

        svg {
          color: theme(text-secondary);
          transition: 0.3s ease-out all;

          &.email {
            width: 2.3125rem;
            height: 1.5625rem;
          }

          &.jsonIcon {
            width: 1.5rem;
            height: 1.5rem;
          }

          &.metamask {
            width: 1.5625rem;
            height: 1.5625rem;
          }
        }

        &:hover {
          top: -0.25rem;
          color: theme(text-primary);

          svg {
            color: theme(text-primary);
          }

          svg.email {
            color: $c-turquoise;
          }
          svg.metamask {
            color: #ef801b;

            :global(#decoration) {
              color: theme(text-primary);
            }
          }

          svg.gridplus {
            color: theme(text-primary);
            :global(#logo) {
              color: theme(text-primary);
            }
            :global(#decoration) {
              color: $c-picton-blue;
            }
          }
        }
      }

      .emailConf {
        background-color: $c-mirage;
        border: 1px solid $c-scampi;
        border-radius: $border-radius;
        padding: 1.875rem 3.75rem;
        padding-top: 0.75rem;
        text-align: center;
        color: $c-titan-white;
        width: 100%;
        max-width: 28.75rem;
        margin: 0 auto;
      }

      .emailConf h3 {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 1.25rem;
      }

      .emailConf .emailConfText {
        font-size: 1rem;
        font-weight: 300;
        position: relative;
      }
    }
  }

  .emailConf {
    background-color: $c-mirage;
    border: 1px solid $c-scampi;
    border-radius: $border-radius;
    padding: 1.875rem 3.75rem;
    padding-top: 0.75rem;
    text-align: center;
    color: $c-titan-white;
    width: 100%;
    max-width: 28.75rem;
    margin: 0 auto;
  }

  .emailConf h3 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  .emailConf p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5625rem;
    margin-bottom: 0;
    min-width: 22.5rem;

    span.email {
      color: $c-heliotrope;
    }
  }

  .emailConf {
    .btnWrapper {
      display: flex;
      justify-content: space-around;
      padding: 0.9375rem 0;

      button {
        width: 100%;
      }
    }
  }

  .magicLink {
    font-weight: 400;
    color: $c-titan-white;
    opacity: 0.8;
    text-align: center;
  }

  .emailAnimation {
    width: 8.75rem;
    height: 8.75rem;
    margin: 0 auto;
  }

  .backButton {
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font-size: 1rem;
    font-weight: 400;
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    color: $c-titan-white;
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    margin-bottom: 3.125rem;

    img {
      margin-right: 0.625rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .loginSignupWrapper {
    gap: 0;
    padding: 0 1em;

    h3 {
      text-align: center;
    }

    .logo {
      width: 75%;
    }

    .addAccount {
      flex-direction: column;
      gap: 2rem;

      .loginSeparator {
        margin: 0;
        width: 100%;
        height: 0.125rem;
      }
    }

    .emailConf {
      padding: 1.25rem;
      padding-top: 0.625rem;

      p {
        min-width: unset;
      }
    }

    .backButton {
      margin-bottom: 0;
    }
  }
}

@include sm-breakpoint {
  .loginSignupWrapper {
    padding-top: 1.25rem;
  }
}
