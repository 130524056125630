@import 'variables';

.wrapper {
  @include flex-center-center;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  background-color: rgba($c-titan-white, 0.05);
  border-radius: $border-radius;

  &.noBackground {
    background-color: transparent;
  }

  &.sm {
    height: 2rem;
    width: 2rem;
    padding: 0.25rem;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}
