@import 'variables.scss';

.wrapper {
  .title {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    color: $c-turquoise;
    margin-bottom: 1.75rem;
  }

  .itemsWrapper {
    background-color: $c-mirage;
    border-radius: $border-radius;
    padding: 1.0625rem 0;
    margin-bottom: 1.25rem;

    .items {
      display: flex;
      flex-direction: column;
      gap: 1.125rem;
      max-height: 11.5625rem;
      overflow: hidden;
      overflow-y: auto;
      padding-right: 1.25rem;
      margin-right: 0.6875rem;
    }
  }

  .emptyMessage {
    text-align: center;
    color: $warning-color;
  }
}

@include lg-breakpoint {
  .wrapper {
    .itemsWrapper {
      .items {
        max-height: 20rem;
        gap: 0.75rem;
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .title {
      font-size: 1.2rem;
      text-align: center;
    }
  }
}
