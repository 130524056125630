@import 'variables';

.wrapper {
  gap: 0;

  .content {
    position: static;

    .confettiAnimation {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -25%);
      width: 50rem;
    }

    .image {
      display: block;
      margin: 0 auto;
    }

    .title {
      font-size: 1.375rem;
      font-weight: 500;
      margin-bottom: 1.25rem;
    }

    .text {
      font-size: 0.875rem;
      opacity: 0.8;
      margin-bottom: 1.25rem;
    }

    .link {
      display: block;
      margin-bottom: 3.75rem;
      color: $c-heliotrope;
    }
  }
}
