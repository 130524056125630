@import 'variables.scss';

.wrapper {
  min-height: 25rem;
  @include flex-center-center;
  flex-direction: column;
  font-size: 1.25rem;
  gap: 1.25rem;

  .text {
    margin: 0;
    text-align: center;
  }

  svg {
    g {
      opacity: 1;
    }
  }
}
