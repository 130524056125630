@import 'variables.scss';

.alert {
  margin-bottom: 1.875rem;

  .link {
    color: $c-heliotrope;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.0625rem;
  border: 1px dashed transparent;

  .title {
    font-size: 1.5625rem;
    font-weight: 400;
  }

  .depositAddress {
    width: 100%;
  }

  .acceptStyleIcon {
    z-index: 2;
    position: absolute;
    color: $c-turquoise;
    top: calc(50% - 3.125rem);
    left: calc(50% - 3.125rem);
  }

  .rejectStyleIcon {
    z-index: 2;
    position: absolute;
    color: $danger-color;
    top: calc(50% - 3.125rem);
    left: calc(50% - 3.125rem);
  }

  &.activeStyle {
    border-color: $c-heliotrope;
    opacity: 0.7;
  }

  &.acceptStyle {
    border-color: $c-turquoise;
    opacity: 0.7;
  }

  &.rejectStyle {
    border-color: $danger-color;
    opacity: 0.7;
  }

  .panel {
    padding: 1.875rem 2.5rem;
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    gap: 0.9375rem;
  }
}
