@import 'variables';

.panelBody {
  overflow-y: auto;
  height: 100%;
}

@include lg-breakpoint {
  .panelBody {
    overflow-y: hidden;
  }
}
