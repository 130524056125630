@import 'variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.375rem;
  background-color: theme(background-primary);
  border-radius: $border-radius;
  color: theme(text-primary);

  &.discount {
    color: theme(highlight-secondary);
  }

  &.warning {
    color: $warning-color;
  }

  .feeSide {
    display: block;
    margin: 0;
    font-weight: 300;
  }
}

@include lg-breakpoint {
  .fee {
    flex-direction: column;
    text-align: center;
    gap: 0.9375rem;
  }
}
