@import 'variables';

.wrapper {
  max-width: 31.25rem;
  width: 100%;
  height: 37.5rem !important;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    svg {
      cursor: pointer;
    }
  }
}
