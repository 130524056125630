@import 'variables.scss';

.wrapper {
  width: 100%;

  .placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    opacity: 0.9;
    text-align: left;
    padding: 0.9375rem;
  }

  .item {
    height: 3.75rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.3125rem !important;
    padding: 0 !important;
    padding-left: 0.9375rem !important;
    cursor: pointer;
    border-radius: $border-radius;

    .inner {
      background-color: transparent;
      border: none;
      color: inherit;
      height: 100%;
      width: 100%;
      min-width: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.9375rem;

      .icon {
        flex: 0 0 auto;
        height: 1.8125rem;
        width: 1.8125rem;
        @include flex-center-center;
        border-radius: 100%;
        background-size: cover;
      }

      .details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.125rem;
        padding-right: 1.25rem;
        overflow: hidden;

        .addressName {
          margin: 0;
          text-align: left;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          font-size: 0.75rem;
          font-weight: 500;
          opacity: 0.5;
        }

        .address {
          font-size: 0.9375rem;
        }
      }
    }

    .button {
      background-color: transparent;
      border: none;
      flex: 0 0 auto;
      height: 100%;
      width: 3.125rem;
      @include flex-center-center;
      font-size: 1.0625rem;
      font-weight: bold;
      color: $c-titan-white;
      border-radius: 0 $border-radius $border-radius 0;

      &:not([disabled]):hover {
        cursor: pointer;
        opacity: 1;
        background-color: $c-mirage-dark !important;
      }

      &:active {
        background-color: $c-fiord !important;
      }
    }

    &:hover {
      background-color: $c-mirage;
    }
  }
}
