@import 'variables.scss';

.wrapper {
  .content {
    cursor: pointer;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    label {
      font-size: 0.875rem;
    }
  }

  .menu {
    width: 25rem;
  }

  .dappList {
    max-height: calc(4.0625rem * 5);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dappsItem {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .icon {
      position: relative;
      flex: 0 0 auto;
      @include flex-center-center;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 100%;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.2);

      .iconOverlay {
        z-index: 49;
        @include absolute-full-size;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }

      img {
        width: 100%;
      }
    }

    span {
      flex: 0 1 auto;

      .details {
        max-width: 11.875rem;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        cursor: pointer;

        .name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .sessionWarning,
        .sessionError {
          @include flex-center-center;
          color: #fdc835;
        }

        .sessionError {
          color: $danger-color;
        }
      }
    }

    .separator {
      flex-grow: 1;
    }

    button {
      flex: 0 0 auto;
      height: 1.5625rem;
      padding: 0 0.625rem;
      font-size: 0.6875rem;
      font-weight: bold;
      border: 1px solid $c-titan-white;
      text-transform: uppercase;
      color: $c-titan-white;
      background-color: transparent;
      opacity: 1;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: $danger-color;
        border-color: transparent;
        cursor: pointer;
      }
    }
  }

  .connectDapp {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;

    .heading {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      padding-right: 0.375rem;

      .helpIcon {
        width: 1.375rem;
        height: 1.375rem;
      }

      .buttonClass {
        width: 100%;
      }

      a {
        display: flex;
        align-items: center;
      }
    }

    label {
      font-size: 0.875rem;
      color: $c-titan-white;
      font-weight: 300;
      margin-bottom: 1.3125rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    .menu {
      min-width: unset;
      width: 100%;
    }
    .dappsItem {
      .details {
        max-width: 8.125rem !important;
      }
    }
  }
}
