@import 'variables.scss';

.wrapper {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .separator {
      flex-grow: 1;
    }

    .dappsList {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .dapp {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 1.125rem;
        font-weight: 500;

        .icon {
          @include flex-center-center;
          height: 1.75rem;
          width: 1.75rem;
          border-radius: 100%;
          background-size: contain;
          background-color: rgba(0, 0, 0, 0.2);

          svg {
            height: 0.875rem;
            opacity: 0.3;
          }
        }
      }
    }

    .message {
      a {
        color: $c-heliotrope;
      }
    }

    .advancedMode {
      .advancedModeLabel {
        font-weight: bold;
      }
    }
  }
}
