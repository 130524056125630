@import 'variables';

.wrapper {
  .actionsLeft {
    padding: 0.9375rem;
    border-radius: $border-radius;
    background-color: $c-mirage;
    color: $warning-color;
    text-align: center;
    margin-bottom: 0.9375rem;
  }

  .migrationPermitted {
    padding: 0.5rem;
    font-size: 0.875rem;
    background: mix($c-turquoise, $c-ebony-clay, 5%);
    color: $c-turquoise;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    gap: 0.25rem;

    &.warning {
      background: mix($c-half-spanish-white, $c-ebony-clay, 5%);
      color: $c-half-spanish-white;
    }
  }
}
