.item {
  height: 4.0625rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 0.625rem;
}

.separator {
  flex-grow: 1;
}
