@import 'variables';

.wrapper {
  overflow: hidden;
  padding: 1rem;
  border-radius: $border-radius;
  background-color: $c-mirage;

  h4,
  p {
    margin: 0;
  }

  .body {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    user-select: none;

    .dappInfo {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      flex: 1.2;

      .dappIcon {
        width: 2.25rem;
        height: 2.25rem;
        padding: 0.5rem;
        border-radius: $border-radius;
        background-color: rgba($c-titan-white, 0.05);
      }
    }

    .typeAndTimestamp {
      display: flex;
      align-items: center;
      flex: 2;

      .type,
      .timestamp {
        flex: 1;
      }
    }

    .expandIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: $border-radius;
      background-color: rgba($c-titan-white, 0.05);

      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  .advanced {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding-top: 0.9375rem;

    .advancedItem {
      .advancedTitle {
        margin-bottom: 0.3125rem;
        font-size: 1rem;
        font-weight: 400;
        user-select: none;
      }

      .advancedText {
        color: $c-scampi;
        font-size: 0.875rem;
        font-weight: 300;
        word-wrap: break-word;
        white-space: break-spaces;
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    padding: 0.75rem;

    .body {
      align-items: stretch;
      font-size: 0.75rem;

      .dappInfo {
        flex-direction: column;
        align-items: flex-start;
      }

      .typeAndTimestamp {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .type,
        .timestamp {
          flex: unset;
        }

        .type {
          color: $c-heliotrope;
          font-size: 0.875rem;
        }
      }

      .expandIcon {
        margin-left: 0.625rem;
      }
    }
    .advanced {
      .advancedItem {
        .advancedTitle {
          font-size: 0.75rem;
        }
        .advancedText {
          font-size: 0.6563rem;
        }
      }
    }
  }
}
