@import 'variables';

.wrapper {
  display: flex;
  flex: 1;
  align-items: center;

  .circle {
    width: 0.5rem;
    height: 0.5rem;
    max-width: 0.5rem;
    max-height: 0.5rem;
    border-radius: 100%;
    border: 1px solid $c-scampi;
  }

  .line {
    flex: 1;
    white-space: nowrap;
    height: 1px;
    border: 1px dashed $c-scampi;
  }
}

@include sm-breakpoint {
  .wrapper {
    min-height: 2.1875rem;
    flex-direction: column;

    .body {
      flex-direction: column;
    }
  }
}
