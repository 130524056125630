@import 'variables';

.fullWidthBtn {
  flex: 1 !important;
  width: 100%;
}

.wrapper {
  margin-bottom: 1rem;

  .titleWrapper {
    text-align: center;
    margin-bottom: 0.9375rem;

    .assetIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 0.625rem;
      height: 4rem;
      width: 4rem;
      border-radius: $border-radius;
      background: rgba($c-titan-white, 0.05);

      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .title {
      margin: 0;
      margin-bottom: 1.875rem;
      font-weight: 500;
      font-size: 1.125rem;

      span {
        color: $c-heliotrope;
        text-transform: capitalize;
      }
    }
  }

  .smallAssetNotification {
    padding: 0.5rem;
    display: block;
    border-radius: $border-radius;
    text-align: center;

    &.warning {
      background: mix($c-half-spanish-white, $c-ebony-clay, 10%);
      color: $c-half-spanish-white;
    }

    &.success {
      background: mix($c-turquoise, $c-ebony-clay, 5%);
      color: $c-turquoise;
    }
  }
}
