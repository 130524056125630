@import 'variables.scss';

.wrapper {
  z-index: 51;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include flex-center-center;
  overflow: hidden;
  padding: 1.25rem;

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
