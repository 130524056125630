@import 'variables.scss';

.select {
  .optionClassName {
    font-size: 0.875rem !important;
    gap: 0.375rem !important;
  }

  .selectInput {
    height: 3.125rem !important;
    padding: 0 0.625rem !important;
    background-color: $c-ebony-clay !important;
  }

  .iconClassName {
    width: 2rem !important;
    height: 2rem !important;
    background-color: $c-mirage !important;
  }

  .labelClassName {
    font-size: 0.875rem;
    font-weight: 300;
  }
}
