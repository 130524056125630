@import 'variables.scss';

.wrapper {
  .content {
    display: flex;
    flex-direction: column;

    .loadingOverlay {
      z-index: 49;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $c-ebony-clay;
    }

    .radiosContainer {
      margin-bottom: 1.875rem;
    }

    form {
      .passwordInputWrapper {
        position: relative;
   
        .passwordStrength {
          right: 50%;
          top: 100px;

          @media screen and (max-width: 1278px) {
            top: 3.125rem;
            right: 1.25rem;
            transform: translate(0, 0);
          }
        }
      }
      
      .passwordInput {
        margin-bottom: 0.625rem;
      }

      .checkboxContainer {
        .checkboxLabel {
          display: flex;
          gap: 0.5em;

          .tooltip {
            svg {
              font-size: 1.75em;
            }
          }
        }
      }
    }

    .warning {
      font-weight: 500;
      color: $danger-color;
    }
  }
}
