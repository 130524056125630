@import 'variables.scss';

.checkboxContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  cursor: pointer;
  margin: 1.25rem 0;
  user-select: none;
  color: theme(text-primary);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(.disabled):hover {
    input,
    .checkbox-mark {
      background-color: #7b7597;
    }
  }

  .label {
    flex: 1;
    font-size: 0.875rem;
  }

  input {
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
    top: 0;
    left: 0;

    &:checked ~ .checkboxMark {
      background-color: $c-turquoise;
      border-color: $c-turquoise;

      &:after {
        display: block;
      }
    }
  }

  .checkboxMark {
    display: block;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    border: 3px solid #51588c;
    border-radius: 0.1875rem;
    background-color: transparent;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 0.375rem;
      top: 0;
      width: 0.3125rem;
      height: 0.875rem;
      border: solid #000;
      border-width: 0 0.1875rem 0.1875rem 0;
      transform: rotate(45deg);
    }
  }
}
