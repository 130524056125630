@import 'variables';

.wrapper {
  transition: opacity 0.1s ease-in-out;
  height: 2.125rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  .icon {
    border-radius: $border-radius;
    width: 2.125rem;
    height: 2.125rem;
    background-color: rgba($c-titan-white, 0.05);
    padding: 0.5rem;
    flex: 1;
    cursor: pointer;
    transition: 0.3s ease-out opacity;

    &:hover {
      opacity: 0.6;
    }
  }

  .dappData {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    a {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $c-heliotrope;
    }

    .infoIcon {
      width: 1.25rem;
      height: 1.25rem;
    }

    .dappLogo {
      width: 2.125rem;
      height: 2.125rem;
      border-radius: $border-radius;
      padding: 0.25rem;
    }

    .exitIcon {
      color: $danger-color;
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    gap: 0.9375rem;

    .menuIcon {
      display: none;
    }

    .dappData {
      flex-direction: row-reverse;
    }
  }
}
