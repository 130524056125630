@import 'variables';

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-bottom: 0.625rem;

    &.noRoutes {
      margin-bottom: 1.25rem;
    }

    .title {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
    }
  }

  .routes {
    gap: 0.75rem;

    .route {
      background-color: $c-mirage;
      border-radius: $border-radius;
      padding: 1.25rem;
      align-items: flex-start;
    }
  }

  .noRoutesPlaceholder {
    margin: 0;
    max-width: 30rem;
    font-size: 0.875rem;
    font-weight: 300;
    opacity: 0.9;
  }
}

@include sm-breakpoint {
  .wrapper {
    .routes {
      .route {
        flex-direction: column;
        padding: 0.9375rem;
      }
    }
  }
}
