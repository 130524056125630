@import 'variables';

.wrapper {
  min-height: 21.875rem;
  max-height: 31.25rem;

  .animation {
    width: 10.8125rem;
    margin: 0 auto;
  }
  .text {
    text-align: center;
    margin: 0;
  }
}
