@import 'variables.scss';

.wrapper {
  .button {
    background-color: transparent;
    border: none;
    color: inherit;
    height: 2.5rem;
    width: fit-content;
    @include flex-center-center;
    gap: 0.4375rem;
    padding: 0 0.9375rem;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
      opacity: 1;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}

#smart-contract-warning {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  font-weight: bold;
  color: $danger-color;

  svg {
    font-size: 1.25rem;
  }
}
