@import 'variables.scss';
$WCBlue: #0078db;
$faveColor: #ffff00;
$gap: 0.75rem;

.wrapper {
  height: 100%;
  @include flex-full-grow-container;
  position: relative;

  .dappCatalogInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .filterInput {
    flex: 1;
  }

  .textInputContainer {
    padding-left: 1.25rem;
    border-radius: $border-radius;

    input {
      background-color: $c-mirage !important;
    }

    ::placeholder {
      color: $c-titan-white !important;
    }
  }

  .filterSection {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
    margin-bottom: ($gap * 2);

    .categories {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;
      gap: $gap;

      .category {
        cursor: pointer;
        padding: 0.7188rem 0.875rem;
        border-radius: $border-radius;
        border: 2px solid mix($c-titan-white, $c-mirage);
        color: mix($c-titan-white, $c-mirage);
        text-transform: lowercase;

        &:hover {
          background: mix($c-ebony-clay, $text-container-color, 85%);
        }

        &.selected {
          border: 2px solid $c-turquoise;
          color: $c-turquoise;

          &.categoryintegrated {
            color: $c-heliotrope;
            border-color: $c-heliotrope;
          }

          &.categorywalletconnect {
            color: $WCBlue;
            border-color: $WCBlue;
          }

          &.categoryfavorites {
            color: $faveColor;
            border-color: $faveColor;
          }
        }
      }
    }
  }

  .dappIframe {
    border: 1px solid $c-heliotrope;
  }

  .catalogItems {
    display: grid;
    grid-template-columns: repeat(4, 1fr [col-start]);
    gap: $gap;

    .catalogItem {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $gap;
      padding: ($gap + 0.875rem) 0.625rem 1.6875rem 1.5625rem;
      background-color: $c-ebony-clay;
      border-radius: $border-radius;
      border: 1px solid transparent;
      transition: 100ms;
      position: relative;
      overflow: hidden;
      transition: 0.1s ease-out border;

      &.addCustomDapp {
        cursor: default;
      }

      .tools {
        position: absolute;
        right: 0.625rem;
        top: 0.625rem;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: center;

        .favorite,
        .customDapp,
        .removeDapp,
        .hotDapp,
        .infoBtn {
          .icon,
          &.icon {
            font-size: 1.25rem;
            height: 1.25rem;
            cursor: pointer;
          }
          height: 0.875rem;
          width: auto;
          color: mix($c-titan-white, $c-mirage, 20%);

          &.selected {
            color: $faveColor;
          }

          &:hover {
            color: $faveColor;
          }
        }

        .favorite {
          height: 1.125rem;
          width: 1.125rem;
        }

        .removeDapp {
          &:hover {
            color: $danger-color;
          }
        }
        .infoBtn {
          color: $ambire-purple;
          opacity: 0.5;
          &:hover {
            color: $ambire-purple;
            opacity: 1;
          }
        }
      }

      &.notSupported {
        cursor: not-allowed;
        opacity: 0.2;

        &:hover {
          background-color: $c-martinique;
        }

        .logoSplit {
          opacity: 0.4;
        }

        .tools :not(.tooltipLabel) {
          opacity: 0.5;
        }

        .tagRow {
          opacity: 0.5;
          .tag.unsupported {
            background-color: transparent;
            border-color: mix($c-titan-white, $c-mirage, 50%);
            color: mix($c-titan-white, $c-mirage, 50%);
          }
        }
      }

      &.addCustomDapp {
        align-items: center;
        gap: $gap * 2;
        .customDappIconWrapper {
          border: 1px dashed $c-fiord;
          border-radius: $border-radius;
          padding: 1.375rem;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;

          img {
            background-color: $c-mirage;
            padding: 0.5rem;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: $border-radius;
          }
        }
      }

      .logoSplit {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 1.25rem;

        .logo {
          min-width: 3.5rem;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: $border-radius;
          background: $c-mirage;
          //padding: .5rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 2.75rem;
            max-height: 2.75rem;
            border-radius: $border-radius;
          }
        }
      }

      .title {
        font-weight: 400;
        display: block;
        font-size: 1.25rem;
      }

      .description {
        margin-top: $gap;
        font-size: 0.875rem;
        color: $c-titan-white;
        opacity: 0.5;
        margin-top: 1.25rem;
      }

      .alignedTagRows {
        display: flex;
        flex-wrap: wrap;
      }

      .tagRow {
        display: flex;
        justify-content: right;
        flex-direction: row-reverse;
        gap: 0.5rem;

        &.networkTagRow {
          gap: 0.25rem;
          justify-content: left;
        }

        &.unsupported {
          justify-content: left;
          margin: 0;
          margin-top: 0.5rem;
        }

        .tooltipNetworks {
          .tooltipNetwork {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin: 0.25rem 0;

            .tooltipNetworkIcon {
              border: 0;
              display: block;
              height: 1rem;
              width: 1rem;
              background-size: 1rem 1rem;
              background-color: transparent;
              border-radius: 2rem;
            }
          }
        }

        .tag {
          // background-color: mix($c-heliotrope, $c-mirage, 30%);
          border: 1px solid $c-heliotrope;
          color: mix($c-heliotrope, $c-titan-white, 70%);
          padding: 0.2188rem 1.3438rem;
          font-size: 0.75rem;
          border-radius: 2rem;
          text-transform: capitalize;

          &.networkTag {
            border: 2px solid $c-ebony-clay;
            display: block;
            height: 1.5rem;
            width: 1.5rem;
            padding: 0 0;
            background-size: 1rem 1rem;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $c-titan-white;
            border-radius: 2rem;
            margin-right: -0.625rem;
            cursor: initial;
          }

          &.networkTagMore {
            background: transparent;
            border: 0;
            margin-left: 0.25rem;
            margin-right: 0;
            font-weight: bold;
            font-size: 1rem;
            margin-left: 0.625rem;
            color: $text-container-color;
          }
        }
      }

      &:hover:not(.addCustomDapp) {
        background-color: $c-martinique;
        border: 1px solid $c-turquoise;
      }
    }
  }

  .noDappFound {
    font-size: 1.2em;
    text-align: center;
    padding: 1.5rem;
    background-color: $c-mirage;
    border-radius: $border-radius;
    margin-top: 1.25rem;
    color: rgba($c-titan-white, 0.8);
  }
}

@include lg-breakpoint {
  .wrapper {
    .filterSection {
      margin: 0 auto;
      margin-bottom: 1.875rem;
      width: 100%;

      .filterInput {
        width: 100%;
      }
    }

    .catalogItems {
      grid-template-columns: repeat(3, 1fr [col-start]);
      justify-items: space-between;

      .catalogItem {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

@include md-breakpoint {
  .wrapper {
    .catalogItems {
      grid-template-columns: repeat(2, 1fr [col-start]);
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .filterSection {
      flex-direction: column;
    }
    .catalogItems {
      grid-template-columns: repeat(1, 1fr [col-start]);

      .catalogItem {
        max-width: 31.25rem;
      }
    }
  }
}
