@import 'variables.scss';

#toast-container {
  z-index: 9999;
  height: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &.hide {
    display: none;
  }

  .transition-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    .toast {
      position: fixed;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.9375rem;
      min-height: 3.125rem;
      max-width: 90%;
      border-radius: $border-radius;
      padding: 0 1.25rem;
      margin-bottom: 1.25rem;
      background-color: $c-turquoise;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
      user-select: none;

      &.error {
        color: $c-titan-white;
        background-color: $danger-color;

        a {
          color: #ccc;
          text-decoration: underline;
        }
      }

      &.warning {
        color: $c-titan-white;
        background-color: $warning-color;

        a {
          color: #ccc;
          text-decoration: underline;
        }
      }

      &.left {
        align-self: flex-start;
        left: 2.1875rem;
      }

      &.right {
        align-self: flex-end;
        right: 2.1875rem;
      }

      &.sticky {
        position: absolute;
        left: inherit !important;
        bottom: 1.875rem;
        margin: 0 !important;
      }

      .inner {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        padding: 0.625rem 0;
        font-size: 0.9375rem;
        font-weight: 500;
        color: black;
        overflow: hidden;

        .badge {
          flex: 0 0 auto;
          height: 1.4375rem;
          width: 1.4375rem;
          @include flex-center-center;
          border-radius: 100%;
          font-size: 0.8125rem;
          color: $c-titan-white;
          background-color: $danger-color;
        }
      }

      .close {
        flex: 0 0 auto;
        height: 1.875rem;
        width: 1.875rem;
        @include flex-center-center;
        font-size: 1.25rem;
        color: black;
        border-radius: 100%;
        transition: $basic-transition;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }
      }

      a {
        color: #222;
        text-decoration: underline;
      }
    }
  }
}

// Mobile
@include lg-breakpoint {
  #toast-container {
    bottom: $sidebar-mobile-height;

    .toast {
      padding: 0 1em !important;

      &.sticky {
        max-width: 100% !important;
        width: 100% !important;
        left: 0;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }
}
