@import 'variables.scss';

.remoteLottie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  background: $c-mirage;
  padding: 0.75rem;
  border-radius: $border-radius;

  span {
    font-size: 0.625rem;
  }
}
