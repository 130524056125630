@import 'variables';

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem;
  width: 100%;
  margin-bottom: 1.875rem;

  .feeSquare {
    flex: 1;
    padding: 0.75rem 0.9375rem;
    border-radius: $border-radius;
    background-color: theme(background-primary);
    border: 1px solid transparent;
    text-align: center;
    transition: 0.3s ease-in-out all;
    // If not set, .feeSquare items min width is set to the width of its content.
    // In other words, the different fees values ($0,01 and 0.000421434) may result in a different square widths.
    // This can result in layouts appearing uneven.
    // Credits: https://dfmcphee.com/flex-items-and-min-width-0/
    min-width: 0;

    .speed,
    .feeEstimation {
      margin: 0;
    }

    .speed {
      margin-bottom: 0.4375rem;
      font-size: 1rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .feeEstimation {
      font-size: 0.875rem;
      font-weight: 300;
      opacity: 0.8;

      max-width: 9.375rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 auto;
      text-overflow: ellipsis;

      &.symbol {
        opacity: 0.6;
      }
    }

    &.disabled {
      opacity: 0.4;
    }

    &:not(.disabled):hover {
      cursor: pointer;
      opacity: 0.4;
    }

    &.selected {
      border: 1px solid theme(highlight-primary);
      background-color: rgba(theme(background-primary), 0.4);
    }

    .discountBadge {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      color: theme(highlight-secondary);
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    grid-gap: 0.3125rem;

    .feeSquare {
      .speed {
        font-size: 0.875rem;
      }

      .feeEstimation {
        font-size: 0.75rem;
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    grid-gap: 0.3125rem;
    .feeSquare {
      padding: 0.375rem;

      .speed {
        font-size: 0.75rem;
      }

      .feeEstimation {
        font-size: 0.6875rem;
        max-width: 3.125rem;
        max-height: 1.875rem;
      }
    }
  }
}
