@import 'variables';

.wrapper {
  padding-right: 0.9375rem; // Temporary fix until we find a better solution for the scrollbar
}

// Adding this padding for laptop screens, because we are sure that the user will
// see a scrollbar below this width
@media screen and (max-width: 1600px) {
  .wrapper {
    padding-right: 1.25rem;
  }
}

@include lg-breakpoint {
  .wrapper {
    padding-right: 0;
  }
}
