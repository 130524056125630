.wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  .firstPart {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  }
}
