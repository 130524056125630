@import 'variables.scss';

.wrapper {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    padding-bottom: 1.875rem;

    .intro {
      opacity: 0.6;
    }

    .permission {
      display: flex;
      gap: 1.875rem;

      .details {
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;

        .name {
          font-size: 1.0625rem;
          font-weight: 500;

          .unavailable {
            padding-left: 0.3125rem;
            font-size: 0.9375rem !important;
            font-weight: bold !important;
            color: $c-heliotrope;
          }
        }

        .description {
          font-size: 0.9375rem;
          opacity: 0.6;
        }

        .unavailable {
          padding-top: 0.3125rem;
          font-size: 0.9375rem !important;
          font-weight: bold !important;
        }
      }

      .status {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .checkIcon {
          svg {
            flex: 0 0 auto;
            font-size: 2.5em;
            color: $c-turquoise;
          }
        }

        label {
          font-size: 0.8em;
          text-align: center;
          opacity: 0.8;
        }

        button {
          flex: 0 0 auto;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .buttons {
      button {
        flex: 0 1 50% !important;
      }
    }
  }
  .downloadBackup {
    opacity: 0.6;
    margin: 0;
  }
}
