@import 'variables';

.row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.wrapper {
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.625rem;
  background-color: $ambire-purple;
  justify-content: space-between;
  gap: 0.9375rem;

  .button {
    margin-left: 0.3125rem;
  }

  .swapInfo {
    font-size: 1.125rem;
    .link {
      color: theme(highlight-secondary);
    }
  }
}
