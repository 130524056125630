@import 'variables.scss';

$max-modal-height: 55.375rem;
$min-modal-height: 34.375rem;

.wrapper {
  z-index: 52;
  position: relative;
  max-height: $max-modal-height;
  min-height: $min-modal-height;
  max-width: 37.75rem;
  width: 100%;
  border-radius: 1.125rem;
  border: 1px $c-fiord;
  background: $c-ebony-clay;

  &.withoutCode {
    min-height: auto;
    padding-bottom: 2rem;
    max-width: 33.75rem;

    .header {
      .headerImage {
        height: auto;
      }
    }
  }
}

// Reset
.wrapper p,
.wrapper h2,
.wrapper span {
  margin: 0;
}

.header {
  overflow: hidden;
  position: relative;
  border-radius: 0.75rem 0.75rem 0 0;

  .headerImage {
    display: block;
    width: 100%;
    height: 15.875rem;
    object-fit: cover;
    object-position: center;
  }

  .closeWrapper {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;

    .remainingTime {
      color: #fff;
      font-size: 0.875rem;
      opacity: 0.6;
      user-select: none;
    }
    .closeIcon {
      cursor: pointer;

      :global(#background) {
        display: none;
      }
    }

    &:not(.closeIconEnabled) {
      .closeIcon {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2.5rem;
}

.title {
  font-size: 1.375rem;
  font-weight: 500;
  color: #fff;
}

.text {
  font-size: 1.125rem;
}

.title,
.text,
.codeWrapper,
.storeWrapper {
  text-align: center;
}

.codeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .codeTitle {
    display: block;
    color: #bbbde4;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .code {
    display: block;
    margin-bottom: 1.5rem;
    border: none;
    width: 18ch;
    background: repeating-linear-gradient(
        90deg,
        $c-scampi 0,
        $c-scampi 1ch,
        transparent 0,
        transparent 1.5ch
      )
      0 100%/98% 1px no-repeat;
    color: #bbbde4;
    font-size: 2rem;
    font-family: monospace;
    letter-spacing: 0.5ch;
  }
  .copyButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0.5rem;
    background: rgba(235, 236, 255, 0.08);
    border-radius: 1.5rem;
    cursor: pointer;

    svg,
    span {
      color: $c-turquoise;
      transition: $basic-transition;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    &:hover {
      background: rgba(235, 236, 255, 0.15);

      svg,
      span {
        color: #fff;
      }
    }
  }
}

.storeWrapper {
  .storeText {
    font-size: 1.125rem;
    margin-bottom: 2rem;
  }
  .storeLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;
    padding: 1rem 3rem;
    margin: 0 auto;
    border-radius: 4rem;
    border: 1px solid $c-scampi;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;

    svg {
      width: 9.25rem;
      height: 2rem;
    }

    &:hover {
      background-color: #0e0e0e;
    }
  }
}

@mixin SmallScreen {
  .content {
    gap: 1.5rem;
  }
  .storeWrapper .storeText,
  .textWrapper .text {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .storeWrapper .storeText {
    margin-bottom: 1.25rem;
  }
  .codeWrapper {
    .codeTitle {
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
    }
  }
}

@media screen and (max-height: calc(#{$max-modal-height} + 100px)) {
  @include SmallScreen;
}

@media screen and (max-height: 680px) {
  .wrapper {
    max-width: 30rem;

    .header {
      .headerImage {
        height: 12.5rem;
      }
    }
    .content {
      .title {
        font-size: 1.125rem;
      }
      .text {
        font-size: 0.875rem;
      }
    }
    .codeWrapper {
      .code {
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }
      .copyButton {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        gap: 0.25rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .storeWrapper {
      .storeText {
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
      }
      .storeLink {
        padding: 0.75rem 2.5rem;
      }
    }
  }
}

@include sm-breakpoint {
  @include SmallScreen;
  .content {
    padding: 1.5rem;
  }
}

@include xs-breakpoint {
  .content {
    padding: 1rem;
  }

  .header {
    .closeWrapper {
      top: 0.25rem;
      right: 0.25rem;
    }
    .headerImage {
      height: 12.5rem;
    }
  }

  .wrapper {
    min-height: 30rem;
  }

  .content {
    gap: 1rem;
  }
  .title {
    font-size: 1.125rem;
  }
  .text {
    font-size: 0.875rem;
  }
  .storeWrapper .storeText,
  .textWrapper .text {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .codeWrapper {
    margin-bottom: 0.5rem;

    .codeTitle {
      font-size: 0.875rem;
    }
    .code {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }
    .copyButton {
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      gap: 0.25rem;
      font-size: 0.875rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .storeWrapper {
    .storeText {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
    .storeLink {
      padding: 0.75rem 2.5rem;
    }
  }
}
