@import 'variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;

  .editCustomFee {
    display: flex;
    align-items: center;
    font-weight: 300;
    opacity: 0.8;
    font-size: 0.875rem;
    gap: 0.625rem;

    margin-bottom: 0.5rem;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .customFeeSelector {
    margin-bottom: 1.25rem;
    width: 100%;

    .title {
      font-size: 1rem;
      font-weight: 300;
      margin: 0;
      margin-bottom: 0.625rem;
    }

    .inputMb {
      margin-bottom: 0.625rem;
    }

    .priceWarning {
      color: $warning-color;
      font-size: 0.875rem;
    }
  }
}
