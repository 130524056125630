@import 'variables.scss';

.stepper {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;

  .step {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    // Specific style for inner steps of the stepper line
    &:not(:first-child):not(:last-child) {
      // Inner steps items (name and status) should be centered.
      // Otherwise the step name and status are positioned/aligned closer to the previous step,
      // instead of having an equal margin from the prev and next steps.
      align-items: center;

      &.stepCurrent {
        // Active progress bar width should end on the status position.
        // Having in mind that we centered the name and status on the above line,
        // the progress bar should also ends in the middle of the step.
        // The other half of the progress bar will have the inactive color.
        .stepBar {
          background: linear-gradient(
            90deg,
            $c-turquoise 50%,
            mix($c-turquoise, $c-mirage, 50%) 50%
          );
        }
      }
    }

    &:nth-last-child(1) {
      .stepStatus {
        margin-left: auto;
      }
      .stepName {
        text-align: end;
      }
    }
    .stepStatus {
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      text-align: center;
      border-radius: 3.125rem;
      z-index: 2;
      border: 2px solid transparent;
    }

    &.stepPrev {
      .stepStatus {
        border-color: $c-turquoise;
        background: $c-mirage;
      }

      .stepBarPrev {
        background: $c-turquoise;
      }
      .stepBarNext {
        background: $c-turquoise;
      }
    }

    &.stepCurrent {
      .stepStatus {
        border-color: $c-turquoise;
        background: $c-turquoise;
      }

      .stepBarPrev {
        background: $c-turquoise;
      }
      .stepBarNext {
        background: mix($c-turquoise, $c-mirage, 50%);
      }
    }

    &.stepNext {
      .stepStatus {
        background: $c-mirage;
        border-color: mix($c-turquoise, $c-mirage, 50%);
      }

      .stepBarPrev {
        background: mix($c-turquoise, $c-mirage, 50%);
      }
      .stepBarNext {
        background: mix($c-turquoise, $c-mirage, 50%);
      }
    }

    .stepBar {
      position: absolute;
      height: 0.125rem;
      z-index: 1;
      top: 0.25rem;

      &.stepBarPrev {
        width: 100%;
        left: 0;
      }

      &.stepBarNext {
        width: 100%;
        right: 0;
      }
    }

    .stepName {
      padding-top: 0.25rem;
      font-weight: normal;
      font-size: 0.75rem;
    }
  }
}
