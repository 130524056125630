@import 'variables';

.modal {
  width: 100%;
  height: 37.5rem;
}

.closeButton {
  flex: 0 1 100% !important;
}

.body {
  padding-top: 2.5rem;
}
