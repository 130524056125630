@import 'variables.scss';

.deposit {
  display: flex;
  justify-content: center;
  gap: 1.0625rem;

  .depositAddress {
    max-width: 31.25rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;

    .textInputLabel {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      text-align: center;
    }
    .inputClass {
      input {
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        padding: 0.8125rem 1.3125rem;
        height: auto;
        color: $c-titan-white;
        font-weight: 200;
      }
    }
  }

  .panel {
    flex: 1;
    width: 36rem;
    text-align: center;
    padding: 2.25rem 2.5rem 1.5625rem 2.5rem;
    overflow: hidden;

    div {
      gap: 0;
    }

    .heading {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      .title {
        @include flex-center-center;
        gap: 0.5rem;
        font-size: 1.5625rem;
        color: $c-titan-white;
      }

      .subtitle {
        font-size: 1rem;
        margin-bottom: 2.3125rem;
        text-transform: uppercase;
        color: $c-turquoise;
      }
    }

    .description {
      text-align: center;
      font-size: 0.875rem;
      color: $c-titan-white;
      user-select: none;

      &.margin {
        margin-bottom: 1.25rem;
      }

      .qrCode {
        height: 10.5rem;
        width: 10.5rem;
        padding: 0.375rem;
        border-radius: $border-radius;
        margin: 0 auto;
        margin-bottom: 2.6875rem;
        background-color: $c-white;
      }
    }

    .networks {
      margin-bottom: 2.5rem;

      &Title {
        margin-bottom: 1.25rem;
        display: block;
        font-size: 0.875rem;
        color: $c-titan-white;
        opacity: 0.8;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.625rem;
        max-width: 43.75rem;
        width: 100%;
        margin: 0 auto;

        .network {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: rgba($c-martinique, 0.4);
          border-radius: $border-radius;
          width: 7.5rem;
          height: 4.375rem;

          .icon {
            height: 2rem;
            width: 2rem;
            @include flex-center-center;
            background-size: cover;
            border-radius: 100%;
          }

          .name {
            font-size: 0.75rem;
            color: $c-titan-white;
          }
        }
      }
    }
  }
}

@include xl-breakpoint {
  .deposit {
    .panel {
      padding: 2rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .deposit {
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .panel {
      width: 100%;
      padding: 1.25rem 1.25rem;

      .networks {
        .logos {
          gap: 1rem !important;
        }

        .list {
          max-width: 100%;
        }
      }
    }
  }
}
