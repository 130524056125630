@import 'variables.scss';

#collectible {
  display: flex;
  gap: 1.875rem;
  .artworkWrapper {
    // max-height: $collectible-size;
    height: 100%;
    align-items: center;
    background-color: rgba($c-fiord, 0.2);

    .artwork {
      width: 100%;
      // max-height: $collectible-size;
    }
  }
  .address {
    font-family: 'Ubuntu Mono';
  }

  .panel {
    min-width: 0;
    flex: 0 1 50%;

    .heading {
      .title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .contract {
        font-size: 0.9375rem;
        font-weight: 500;
        opacity: 0.6;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .content {
      height: 100%;

      .separator {
        flex-grow: 1;
      }
    }
  }

  .metadata {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    overflow: hidden;

    .collection {
      font-size: 0.9375rem;
      font-weight: bold;
    }

    .image {
      z-index: 47;
      height: 15.625rem;
      width: 15.625rem;
      @include flex-center-center;
      font-size: 2.5rem;
      color: rgba(255, 255, 255, 0.5);
      border-radius: $border-radius;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: $c-mirage;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;

      .name {
        font-size: 1.125rem;
        font-weight: bold;
        color: $c-titan-white;
      }

      .description {
        font-size: 0.9375rem;
        font-weight: 500;
        color: $c-titan-white;
        opacity: 0.6;
      }
    }

    .owner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.3125rem;
      margin-top: 0.625rem;
      font-size: 0.9375rem;
      font-weight: 500;
      opacity: 0.6;
      overflow: hidden;
      white-space: nowrap;

      .address {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .icon {
          height: 0.875rem;
          width: 0.875rem;
          border-radius: 100%;
        }
      }
    }
  }

  #recipient-address {
    position: relative;
    display: flex;

    .recipient-input {
      width: 100%;

      input {
        border-radius: $border-radius 0 0 $border-radius;
        padding-right: 17.5rem;
      }
    }

    #udomains-logo {
      position: absolute;
      top: 1.125rem;
      right: 17.1875rem;
      height: 1.5625rem;
      width: 1.5625rem;
      background-image: url('/resources/ud-logo-inactive.png');
      background-size: cover;
    }

    .ud-logo-active {
      background-image: url('/resources/ud-logo-active.png') !important;
    }

    #ens-logo {
      position: absolute;
      top: 1.125rem;
      right: 15rem;
      height: 1.5625rem;
      width: 1.5625rem;
      background-image: url('/resources/ens-logo-inactive.png');
      background-size: cover;
    }

    .ens-logo-active {
      background-image: url('/resources/ens-logo-active.png') !important;
    }

    .address-book {
      position: absolute !important;
      right: 0;
    }
  }
}

// Mobile
@include lg-breakpoint {
  #collectible {
    flex-direction: column;
    gap: 0.9375rem;

    #recipient-address {
      flex-direction: column;
      gap: 0.625rem;

      .recipient-input {
        input {
          padding-right: 3.125rem;
        }
      }

      #udomains-logo {
        position: absolute;
        top: 1.125rem;
        right: 3.125rem;
        height: 1.5625rem;
        width: 1.5625rem;
      }

      #ens-logo {
        position: absolute;
        top: 1.125rem;
        right: 0.9375rem;
        height: 1.5625rem;
        width: 1.5625rem;
      }

      .address-book {
        position: relative !important;
      }

      .dropdown {
        position: relative !important;
      }
    }
  }
}
