@import 'variables.scss';

.transferButton {
  max-width: 25rem;
  width: 100%;
  margin: 0 auto;
  min-height: 3.75rem;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem;
  padding-top: 0.9375rem;
  gap: 2.5rem;
  overflow-y: auto;

  .confirmations {
    padding-left: 1.25rem;
  }

  .validationError {
    color: $danger-color;
    text-align: center;
    font-size: 0.875rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    flex: 1;
  }

  .amountLabel {
    font-size: 0.875rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $c-titan-white;
  }

  .separator {
    flex-grow: 1;
    @include flex-center-center;
    font-size: 2.5rem;
    opacity: 0.5;
  }

  .gasTankMsg {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background-color: mix($c-ebony-clay, $warning-color, 90%);
    color: $warning-color;
    padding: 0.9375rem;
    font-size: 0.875rem;
    border-radius: $border-radius;

    svg {
      color: $c-ebony-clay;
      width: 2.25rem;
      height: 2.25rem;
    }
  }

  .gasTankConvertMsg {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background-color: mix($c-ebony-clay, $c-turquoise, 90%);
    color: $c-turquoise;
    padding: 0.9375rem;
    font-size: 0.875rem;
    border-radius: $border-radius;
  }

  .binanceAddressWarning {
    margin: 0;

    .checkBoxLabel {
      vertical-align: middle;
      margin-top: 0.1875rem;
    }
    a {
      vertical-align: middle;
      margin-left: 0.625rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    gap: 1.875rem;
    padding: 1.25rem;
  }
}
