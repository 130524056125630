@import 'variables';

.panel {
  padding: 0;

  .tab {
    position: relative;
    flex: 1;
    overflow-y: auto;
  }
}

.buttons {
  display: flex;
  position: relative;

  .shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #1e2033 0%, #24263d 100%);
    height: 5.9375rem;
    width: 100%;
    transform: translateY(100%);
  }

  .button {
    font-family: 'Poppins', sans-serif;
    flex: 1;
    padding: 0.9375rem;
    font-size: 1.5625rem;
    color: $c-scampi;
    background-color: transparent;
    border: none;
    font-weight: 400;
    border-bottom: 2px solid $c-fiord;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:first-child {
      border-radius: $border-radius 0 0 0;
    }

    &:nth-child(2) {
      border-radius: 0 $border-radius 0 0;
    }

    &.active {
      color: $c-titan-white;
      border-bottom-color: $c-turquoise;

      &:first-child {
        border-right: 1px solid rgba($c-scampi, 0.4);
      }

      &:nth-child(2) {
        border-left: 1px solid rgba($c-scampi, 0.4);
      }
    }
    &:not(.active) {
      &:first-child {
        border-right: 3px solid $c-mirage;
      }

      &:nth-child(2) {
        border-left: 3px solid $c-mirage;
      }
    }
  }
}
