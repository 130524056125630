@import 'variables.scss';

.wrapper {
  position: sticky;
  z-index: 49;
  padding: 2.8125rem $layout-spacing 2.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.875rem 0;
  width: 100%;
  color: $c-titan-white;
  min-height: 6rem;

  &.visualEnv {
    margin-top: 0.75rem;

    .envBar {
      color: $c-titan-white;
      text-align: center;
      font-size: 0.625rem;
      line-height: 0.6875rem;
      font-weight: bold;
      height: 0.75rem;
      width: 100%;
      position: absolute;
      top: -0.75rem;
      left: 0;
      display: block;
    }

    &.visualEnvdev {
      .envBar {
        background: repeating-linear-gradient(
          -45deg,
          #0066ff 0,
          #0066ff 1.875rem,
          #0048b6 1.875rem,
          #004cbd 2.5rem
        );
      }
    }

    &.visualEnvstaging {
      .envBar {
        background: repeating-linear-gradient(
          -45deg,
          #e69b00 0,
          #e69b00 1.875rem,
          #c08b00 1.875rem,
          #c08b00 2.5rem
        );
      }
    }
  }

  .mobileMenu {
    display: none;
    align-items: center;
    gap: 0.9375rem;
    padding: 0 1.25rem;

    .logo {
      width: 6.25rem;
    }

    .mobileMenuRight {
      display: flex;
      gap: 0.9375rem;
      align-items: center;

      .icon {
        height: 1.5625rem;
        width: 1.5625rem;
        background-color: $c-mirage;
        border-radius: 0.5rem;
      }

      .menuButton {
        @include flex-center-center;
        font-size: 1.875rem;
        margin-left: 0.625rem;
        width: 2.5rem;
        height: 2.5rem;

        .menuIcon {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $border-radius;
          background-color: rgba($c-titan-white, 0.05);
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.875rem;
    flex-wrap: wrap;
    max-width: 105.625rem;
    width: 100%;

    .privacyAndRewards {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;

      svg {
        width: 2.125rem;
        height: 2.125rem;
      }
    }
  }

  &.dappMode {
    padding: 1.25rem;
    align-items: center;

    .container {
      max-width: unset;
      width: unset;
      flex: 1;
    }

    .mobileMenu {
      padding: 0;
      width: auto;
    }
  }
}

@include xxl-breakpoint {
  .wrapper {
    padding: 2.8125rem $layout-spacing / 2.5 2.5rem 0;
  }
}

// On this breakpoint, the TopBar items overflow and are wrapped on a second row.
// In order to prevent it, we decrease the items gap.
@include xl-breakpoint {
  .wrapper {
    padding: 2rem 2rem 2rem 0;

    .container {
      gap: 1.3125rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .mobileBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-color: $c-ebony-clay;
    opacity: 0;
    transition: 0.3s ease-out opacity;
    &.visible {
      opacity: 0.8;
      z-index: 48;
    }
  }

  .wrapper {
    z-index: 50;
    box-shadow: 0 4px 10px #00000073;
    border: 1px solid $c-fiord;
    padding: 1.25rem 0;
    background-color: $c-fiord;

    .mobileMenu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 0.625rem;
    }

    .container {
      position: absolute;
      flex-direction: column;
      align-items: stretch;
      top: 5.8125rem;
      left: 0;
      right: 0;
      gap: 0.625rem;
      display: none;
      padding: 0.625rem 0.625rem;
      box-shadow: $box-shadow;
      background-color: $c-fiord;

      .privacyAndRewards {
        display: flex;
        justify-content: flex-end;
        gap: 1.25rem;
      }

      .dropdown {
        width: 100%;

        .menu {
          width: 100%;
        }
      }

      .select {
        width: 100%;
      }

      &.open {
        display: flex;
      }
    }
  }
}
