@import 'variables.scss';

$max-width: 40.625rem;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
  background: theme(background-gradient);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.0625rem;
  padding: 2rem;

  .panel {
    width: $max-width;

    .content {
      flex-wrap: wrap;
    }

    .signMessageTitle {
      display: flex;
      align-items: baseline;

      .signMessageTitleTitle {
        flex-grow: 1;
      }
      .signMessageTitleSignatureType {
        display: flex;
        align-items: center;
        background-color: theme(background-primary);
        padding: 0.125rem 0.5rem;
        color: $text-container-color;
        font-weight: 300;
        font-size: 1rem;
        border-radius: $border-radius;

        span {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .requestMessage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 500;

    .dappMessage {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      row-gap: 0.1875rem;
      align-items: center;
      flex-wrap: wrap;

      .dapp {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        color: theme(highlight-primary);

        .icon {
          height: 1.375rem;
          width: 1.375rem;
          border-radius: 100%;
          @include flex-center-center;
          background-size: contain;
          background-color: theme(background-icon);

          svg {
            font-size: 0.75rem;
            opacity: 0.2;
          }
        }
      }
    }

    span {
      opacity: 0.5;
    }

    .warning {
      color: $warning-color;
    }
  }

  .signMessage {
    width: auto;
    padding: 2rem;
    color: theme(text-secondary);
    min-height: 18.75rem;
    background-color: theme(background-primary);
    border: 0;
    border-radius: $border-radius;
    resize: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .actions {
    form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .buttons {
        display: flex;
        gap: 1.5rem;

        .button {
          flex: 1 1 50%;
        }

        .icon {
          position: absolute;
          top: 1.25rem;
          left: 1.25rem;
        }
      }
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    gap: 1rem;
    padding: 1rem;

    .panel {
      width: 100%;
      min-width: auto !important;
      max-width: 100% !important;
      padding: 1.25rem 1rem !important;

      .panelTitle {
        justify-content: center;
        font-size: 1.25rem;
      }

      .signMessageTitle {
        .signMessageTitleSignatureType {
          font-size: 0.75rem;
        }
      }
    }

    .requestMessage {
      .dappMessage {
        font-size: 0.75rem;

        a {
          display: flex;
          align-items: center;
        }
      }
    }
    .signMessage {
      font-size: 0.75rem;
      min-height: 11.25rem;
      padding: 0.625rem 1.25rem;
      padding-bottom: 0.9375rem;
    }
    .actions {
      form {
        .buttons {
          flex-direction: column-reverse;
          gap: 0.625rem;

          .button {
            flex: unset;
            font-size: 1rem;
            height: 3.125rem;
          }
        }
      }
    }
  }
}
