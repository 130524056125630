@import 'variables';

.wrapper {
  margin-bottom: 1.25rem;

  .title {
    font-size: 1rem;
    font-weight: 300;
    color: theme(text-primary);
    margin: 0;
    margin-bottom: 0.625rem;
  }
}
