@import 'variables';

.replaceInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: $border-radius;

  &.info {
    background-color: $info-background-color;
    color: $alert-text-color;
  }

  &.warning {
    background-color: $warning-background-color;
    color: $alert-text-color;
  }

  margin-bottom: 1rem;
}

.wrapper {
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    flex-wrap: wrap;

    .button {
      height: 4.6875rem;
      flex-grow: 1;
    }
  }
}
