@import 'variables.scss';

.wrapper {
  position: relative;

  .shadow {
    position: absolute;
    left: 50%;
    z-index: 1;
    bottom: 0;
    width: 99%;
    height: 2.5rem;
    transform: translateX(-50%);
    background: rgba($c-mirage, 0.8);
    mix-blend-mode: multiply;
    border-radius: 0.625rem;
    filter: blur(0.3125rem);
  }

  .alertWrapper {
    overflow: hidden;
    border-radius: $border-radius;

    .alert {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 2;
      gap: 1.25rem;
      padding: 0.8125rem 1.25rem;
      padding-left: 0.8125rem;
      color: $c-martinique-light;
      border-left: 0.4375rem solid;

      &.alertIconNextToTitle {
        padding-left: 1.25rem;
      }

      svg {
        width: 2.125rem;
        height: 2.125rem;
      }

      &.success {
        background-color: $success-background-color;
        border-left-color: $success-color;
      }

      &.warning {
        background-color: $warning-background-color;
        border-left-color: $warning-color;
      }

      &.danger {
        background-color: $danger-background-color;
        border-left-color: $danger-color;
      }

      &.info,
      &.degenTip {
        background-color: $info-background-color;
        border-left-color: $ambire-dark-blue;
      }

      .body {
        flex: 1;

        .titleWrapper {
          display: flex;
          align-items: center;
          gap: 1.25rem;

          .title {
            margin: 0;
            font-size: 1.25rem;
          }
        }

        .text {
          margin: 0;
          font-size: 1rem;
          text-align: left;

          .link {
            color: $c-heliotrope;
          }
        }
      }

      // Sizes
      &.small {
        .body {
          .titleWrapper {
            gap: 0.625rem;

            .title {
              font-size: 1rem;
              font-weight: 400;
            }
          }

          .text {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

@include xl-breakpoint {
  .wrapper {
    .alertWrapper {
      .alert {
        svg {
          width: 1.875rem;
          height: 1.875rem;
        }

        .body {
          .titleWrapper {
            gap: 0.9375rem;

            .title {
              font-size: 1.125rem;
            }
          }

          .text {
            font-size: 0.875rem;
          }
        }

        .close {
          margin-left: 1.875rem;
          cursor: pointer;
          color: $warning-color;
          background-color: transparent;
          border: 0;
          outline: none;
        }

        // Sizes
        &.small {
          gap: 0.625rem;

          .body {
            .titleWrapper {
              .title {
                font-size: 0.9375rem;
              }
            }
            .text {
              font-size: 0.8125rem;
            }
          }
        }
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    .alertWrapper {
      .alert {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        gap: 0.625rem;
        border-left: 0;
        border-top: 0.4375rem solid;
        text-align: center;

        &.info,
        &.degenTip {
          border-top-color: $ambire-dark-blue;
        }

        &.success {
          border-top-color: $success-color;
        }

        &.warning {
          border-top-color: $warning-color;
        }

        &.danger {
          border-top-color: $danger-color;
        }

        svg {
          width: 1.75rem;
          height: 1.75rem;
        }

        .body {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
          margin-left: 0;

          .titleWrapper {
            flex-direction: column;
            justify-content: flex-start;
            gap: 0.3125rem;

            .title {
              font-size: 1rem;
            }
          }

          .text {
            font-size: 0.75rem;
            text-align: center;
          }
        }

        .close {
          margin-left: 1.875rem;
          cursor: pointer;
          color: $warning-color;
          background-color: transparent;
          border: 0;
          outline: none;
        }
      }
    }
  }
}
