@import 'variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: theme(background-gradient);
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 2.875rem;
    max-width: 110rem;
    padding: 2.3125rem 2.5rem;

    .containerBody {
      overflow: hidden;
      flex: 1;
      display: flex;
      gap: 1rem;

      .panel {
        flex: 1;
        padding: 1.875rem 2.5rem;

        &:first-child {
          flex: 2;
        }

        & > div {
          // Panel .content div
          justify-content: space-between;
        }

        .panelTitle {
          margin: 0;
          margin-bottom: 1.6875rem;
          font-size: 1.5625rem;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
}
// Here we are not using a breakpoint from variables.scss, but SendTransaction
// covers the whole screen, so it shouldn't be a problem.
@media screen and (max-width: 1600px) {
  .wrapper {
    .container {
      .containerBody {
        .panel {
          &:first-child {
            flex: 1.5;
          }

          .panelTitle {
            font-size: 1.4375rem;
          }
        }
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    .container {
      padding: 2.5rem 1.25rem;
      padding-top: 0.3125rem; // Fix for the Back Icon being cut
      max-width: 56.25rem;
      margin: 0 auto;
      margin-top: 1.875rem;
      overflow-y: auto;

      .containerBody {
        overflow: unset;
        flex-direction: column;
        gap: 2.5rem;
        flex: unset;

        .panel {
          overflow-y: unset;
          padding: 1.25rem;

          .panelTitle {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .container {
      padding: 1.875rem 0.9375rem;
      padding-top: 0.3125rem;
      margin-top: 0.9375rem;

      .containerBody {
        gap: 1.0625rem;
        .panel {
          padding: 0.9375rem;
        }
      }
    }
  }
}
