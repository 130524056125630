@import 'variables.scss';

.error-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-screen-content {
    max-width: 31.25rem;
    text-align: center;
    background: $c-ebony-clay;
    border: 1px solid $c-fiord;
    border-radius: $border-radius;

    p {
      margin: 0;
    }

    .error-screen-content-refresh-btn {
      font-weight: bold;
    }
  }

  .logo {
    display: inline-block;
    height: 4.375rem;
    width: 14.6875rem;
    background-image: url('/resources/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 0.1s ease-in-out;
  }

  .error-screen-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.25rem;

    > a {
      width: 50%;

      button {
        width: 100%;
      }
    }
  }
}
