@import 'variables';

.button {
  font-weight: 300;
  width: 13rem;
  font-size: 0.875rem !important;
  padding: 0.9375rem 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 4.0625rem;
  }
}
