@import 'variables.scss';

.checkboxLabel {
  color: theme(text-primary);
  
  a {
    color: theme(highlight-primary);
  }
}

.passwordInputWrapper {
	position: relative;
	margin: 2rem 0;

	.passwordInput {
		margin: 0;
		
		&.error {
			border-bottom-color: $danger-color;
	
			&:not([type="submit"]):focus {
				border-bottom-color: $danger-color!important;
			}
		}
	}
}

.confirmPassword.error {
	border-bottom-color: $danger-color;

	&:not([type="submit"]):focus {
		border-bottom-color: $danger-color!important;
	}
}