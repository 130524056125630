@import 'variables';

.wrapper {
  .addressInput {
    margin-bottom: 1.25rem;
    label {
      font-weight: 400;
      font-size: 0.875rem;
      margin-left: 1.25rem;
    }
  }

  .tokens {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    .token {
      flex-direction: row;
      justify-content: space-between;
      background-color: $c-mirage;
      height: 3.125rem;
      padding: 0.5rem 1.25rem;
      border-radius: $border-radius;
      margin-bottom: 0;

      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}
