@import 'variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.0625rem;

  p {
    margin: 0;
  }

  .panel {
    flex: 0 1 50%;
    padding: 1.875rem 2.5rem;
    gap: 2.5rem;

    &Title {
      font-weight: 400;
      font-size: 1.5625rem;
    }

    .export {
      .button {
        margin-bottom: 1.25rem;
        height: 3.75rem;
        width: 100%;
        background: transparent linear-gradient(90deg, $ambire-dark-blue 0%, $ambire-dark-blue 100%)
          0% 0%;
        border: none;
        color: $c-titan-white;

        svg {
          color: $c-titan-white !important;
        }
      }
    }

    .import {
      .button {
        margin-bottom: 1.25rem;
        height: 3.75rem;
        width: 100%;
        background: transparent linear-gradient(90deg, $c-turquoise 0%, #007d8a 100%) 0% 0%;
        border: none;
        color: $c-titan-white;

        svg {
          color: $c-titan-white !important;
        }
      }
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    flex-direction: column;
    gap: 1rem;
  }
}
