@import 'variables.scss';

$sidebar-desktop-width: 16.25rem;

.wrapper {
  position: relative;
  z-index: 49;
  height: 100%;
  width: $sidebar-desktop-width;
  padding-top: 2.8125rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: width 0.1s ease-in-out;
  background-color: $c-ebony-clay;
  transition: left 0.3s ease-in-out;

  &:after {
    content: '';
    width: 0.125rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: -0.0625rem;
    background: rgb(61, 68, 106);
    background: linear-gradient(
      180deg,
      rgba(61, 68, 106, 1) 0%,
      rgba(53, 58, 91, 1) 25%,
      rgba(100, 111, 174, 1) 50%,
      rgba(58, 63, 99, 1) 75%,
      rgba(37, 40, 63, 1) 100%
    );
  }

  .outsideHandler {
    display: none;
  }

  &.dappMode {
    position: fixed;
    z-index: 51;
    left: -($sidebar-desktop-width + 20);
    box-shadow: 15px 10px 20px 0 rgba(0, 0, 0, 0.25);
    padding-top: 0;

    &.open {
      left: 0;
    }

    .outsideHandler {
      display: block;
      position: fixed;
      left: $sidebar-desktop-width;
      bottom: 0;
      top: 0;
      right: 0;
    }
  }

  .sidebarLogo {
    max-width: 8.625rem;
    margin: 0 0 1.875rem 1rem;
  }

  .ambireLogo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 4.6875rem;
    padding: 0.9375rem;
    gap: 0.5rem;
    .logo,
    .icon {
      height: 100%;
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
    }

    .logo {
      background-image: url('/resources/logo.png');
    }

    .icon {
      background-image: url('/resources/icon.png');
      display: none;
    }
  }

  .balance {
    margin-bottom: 5rem;
    padding-left: 1rem;

    label {
      font-size: 0.9375rem;
      line-height: 1.4375rem;
      color: $c-titan-white;
      text-transform: uppercase;
    }

    .balanceDollarAmount {
      transition: font-size 0.1s ease-in-out;
    }
  }

  nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;

    .item {
      display: flex;
      align-items: center;
      gap: 1.6875rem;
      padding: 0.75rem;
      cursor: pointer;
      color: $c-manatee;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      text-align: center;
      white-space: nowrap;
      transition: all 0.1s ease-in-out;

      svg {
        width: 1.875rem;
        height: 1.875rem;
        color: $c-manatee;
        transition: color 0.1s ease-in-out;
      }

      &:hover {
        background-color: $c-martinique !important;
        color: $c-turquoise;

        svg {
          color: $c-turquoise;
        }
      }

      .badge-wrapper {
        font-weight: bold;
        width: 1.875rem;
        height: 1.875rem;
        text-align: center;
        border: 1px solid $danger-color;
        border-radius: 50%;
        background: $danger-color;
      }

      .opensea-icon {
        height: 1.875rem;
        width: 1.875rem;
        background-size: contain;
        background-image: url('../../../resources/opensea.svg');
        filter: grayscale(1);
      }

      &.helpLink {
        color: $c-heliotrope;

        svg {
          color: $c-heliotrope;
        }
      }
    }
  }

  a.selected .item svg {
    color: $c-turquoise;
  }
  a.selected .item {
    background-color: $c-martinique;
    color: $c-turquoise;

    .opensea-icon {
      filter: grayscale(0);
    }
  }

  .highlight {
    color: $c-turquoise;
  }

  .dollarSign {
    margin-right: 0.3125rem;
  }

  .balanceDollarAmount {
    font-size: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@include xl-breakpoint {
  .wrapper {
    padding-top: 2rem;

    nav {
      .item {
        gap: 1.25rem;
        font-size: 0.9375rem;

        svg {
          width: 1.875rem;
          height: 1.875rem;
        }
      }
    }

    .balance {
      margin-bottom: 3rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    position: fixed;
    bottom: 0;
    height: $sidebar-mobile-height;
    width: 100%;
    padding: 0;
    z-index: 50;

    .balance,
    .sidebarLogo {
      display: none;
    }

    &.dappMode {
      display: none;
      position: relative;

      nav {
        height: 0;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .item {
        flex-direction: column;
        justify-content: center;
        gap: 0;
        height: 100%;
        width: 9.3125rem;
        font-size: 0.8125rem;
        font-weight: 500;
        padding: 0;

        svg {
          font-size: 1.5625rem;
        }
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    nav {
      .item {
        width: 6.5rem;
      }
    }
  }
}
