@import 'variables.scss';

.unavailable {
  padding-top: 3.125rem;
  max-width: 50rem;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: center;
  gap: 1.0625rem;
  min-height: 100%;

  .panel {
    flex: 1;
    padding: 2.5rem;
    min-height: 100%;
  }
}

@include lg-breakpoint {
  .wrapper {
    flex-direction: column;

    .panel {
      padding: 1.875rem;
      min-height: unset;
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .panel {
      padding: 1.25rem;
    }
  }
}
