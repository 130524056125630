@import 'variables.scss';

.wrapper {
  .content {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .token {
      display: flex;
      background-color: $c-mirage;
      border-radius: $border-radius;
      padding: 0.5rem 1.25rem;

      p {
        opacity: 0.9;
        font-weight: 300;
        margin: 0;
        font-size: 0.875rem;
      }

      .iconAndName {
        display: flex;
        flex: 2 0 0;
        gap: 0.625rem;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.125rem;
          height: 2.125rem;
          border-radius: $border-radius;
          background-color: rgba($c-titan-white, 0.05);

          img {
            width: 1.125rem;
            height: 1.125rem;
          }
        }
      }
      .amountAndValue {
        display: flex;
        align-items: center;
        flex: 3 0 0;

        .amount {
          flex: 4;
          text-align: left;
        }

        .value {
          flex: 2;
          text-align: right;
        }
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    .content {
      .token {
        align-items: flex-start;
        justify-content: space-between;

        .iconAndName {
          flex: unset;
        }
        .amountAndValue {
          flex: unset;
          gap: 0.3125rem;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}
