@import 'variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 15rem;
  background: $c-mirage;
  border: 0.0625rem solid $c-scampi;
  border-radius: $border-radius;
  padding: 0.875rem;
  padding-top: 0.9375rem;

  &.active {
    border-color: $c-turquoise;
  }

  .manage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.875rem;

    .makeDefault {
      padding: 0 0.75rem;
      height: 2.125rem;
      border-radius: $border-radius;
      background-color: transparent;
      border: 0.125rem solid $c-scampi;
      font-family: inherit;
      color: $c-scampi;
      font-weight: 500;
      font-size: 0.875rem;
      cursor: pointer;

      &.default {
        cursor: not-allowed;
        color: $c-turquoise;
        border-color: $c-turquoise;
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.125rem;
      height: 2.125rem;
      padding: 0;
      background-color: transparent;
      border: 0.0625rem rgba($danger-color, 0.8) solid;
      border-radius: $border-radius;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: unset;
      }

      svg {
        color: $danger-color;
        width: 2.125rem;
        height: 2.125rem;
      }
    }
  }

  .body {
    margin-bottom: 3.125rem;
    text-align: left;
    font-size: 1rem;

    label {
      display: block;
      margin: 0;
      word-wrap: break-word;

      &:first-child {
        margin-bottom: 0.625rem;
      }
    }

    .privText {
      color: $c-scampi;
    }
  }

  .buttons {
    margin-top: auto;
    .button {
      width: 100%;
      height: 3.125rem;
      font-size: 1rem;
      padding: 0.8125rem 0;
      margin-bottom: 0.625rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    max-width: 17.5rem;
  }
}
