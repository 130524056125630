@import 'variables.scss';

.alert {
  margin: 1.25rem 0;
}

.title {
  display: flex;
  justify-content: space-between;

  span {
    font-size: 1rem;
    margin-bottom: 0.625rem;
    font-weight: 300;
  }

  .gasTankEnabled {
    color: theme(highlight-secondary);
  }

  .gasTankDisabled {
    color: $danger-color;
  }
}

@include lg-breakpoint {
  .title {
    flex-direction: column-reverse;
    text-align: center;
    gap: 1.25rem;
    margin-bottom: 1.25rem;

    span {
      margin-bottom: 0;
    }
  }
}
