@import 'variables';

$txn-height: 4.0625rem;
$extended-summary-height: 2.25rem;

.action {
  display: flex;
  align-items: center;
  height: $extended-summary-height;
  color: theme(highlight-secondary);
  font-size: 0.875rem;

  &.unknown {
    color: #d06f1b;
  }

  &.cancel {
    color: #c33a3a;
  }
}

.word {
  font-size: 0.8125rem;
  color: theme(text-secondary);
}

.token,
.address,
.network,
.erc721 {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.875rem;
  flex-wrap: wrap;
  color: theme(text-primary);

  .icon {
    height: 2.25rem;
    width: 2.25rem;
    padding: 0.5rem;
    border-radius: $border-radius;
    background-color: theme(background-icon);
  }
}

.erc721,
.address {
  .toAddress.short {
    display: none;
  }
}
.externalLink {
  width: 1.5rem;
  height: 1.5rem;
}

@include lg-breakpoint {
  .word {
    font-size: 0.6875rem;
  }

  .action,
  .token,
  .address,
  .network,
  .erc721 {
    font-size: 0.75rem;

    .icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.25rem;
      border-radius: $border-radius;
      background-color: theme(background-icon);
    }
  }

  .token,
  .address,
  .network,
  .erc721 {
    gap: 0.3125rem;
    row-gap: 0.125rem;
  }

  .action {
    height: auto;
  }

  .erc721,
  .address {
    .toAddress {
      display: none;
    }

    .toAddress.short {
      display: block;
    }
  }

  .externalLink {
    width: 1rem;
    height: 1rem;
  }
}
