.slide-fade-enter {
  opacity: 0;
  transform: translateY(3.125rem);
}

.slide-fade-enter.slide-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.slide-fade-enter-done {
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-exit.slide-fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.slide-fade-exit-done {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fade-exit-done {
  opacity: 0;
}
