@import 'variables.scss';

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;

  .title {
    color: $c-scampi;
    font-size: 1.125rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1.5625rem;
  }

  .intro {
    color: $text-container-color;
  }

  .signers {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .signer {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1.25rem;
      cursor: pointer;
      background: $c-mirage;
      border-radius: $border-radius;
      border: 1px solid transparent;
      transition: border-color 0.3s ease;

      .link {
        display: flex;
        align-items: center;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      &:hover {
        border: 1px solid $c-turquoise;
      }
    }
  }

  .info {
    margin-top: auto;
    margin-bottom: 1.25rem;
  }

  .pagination {
    margin-bottom: 1.875rem;
    display: flex;
    justify-content: flex-end;
  }
}
