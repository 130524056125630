@import 'variables';

.wrapper {
  margin-bottom: 1.25rem;

  .input {
    margin-bottom: 0.625rem;
  }

  .buttons {
    display: flex;
    gap: 0.625rem;

    .button {
      flex: 1;
    }
  }

  .loading {
    padding: 0.5rem;
    text-align: center;
    background: $c-mirage;
  }

  .error {
    background: $danger-color;
    color: $c-white;
    padding: 0.5rem;
    margin-bottom: 1.125rem;
    border-radius: $border-radius;
  }

  .openFormButton {
    width: 100%;
  }
}
