@import 'variables';

.wrapper {
  margin-bottom: 1.875rem;

  .title {
    margin: 0;
    margin-bottom: 0.625rem;
    font-size: 1rem;
    font-weight: 300;
    color: theme(text-primary);
  }
}

@include lg-breakpoint {
  .wrapper {
    .title {
      text-align: center;
    }
  }
}
