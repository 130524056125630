@import 'variables';

.wrapper {
  height: 100%;
}

@include lg-breakpoint {
  .wrapper {
    .buttons {
      button {
        font-size: 0.875rem !important;
      }
    }
  }
}
