@import 'variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: 1.25rem;
  background: $c-ebony-clay;
  border: 0.0625rem solid $c-fiord;
  border-radius: $border-radius;

  .message {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .button {
      cursor: pointer;
      display: block;
      color: $c-turquoise;
      text-decoration: underline;
      padding: 0;

      &.buttonMargin {
        margin-top: 0.5rem;
        align-self: center;
      }
    }
  }

  .close {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
  }
}
