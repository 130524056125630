@import 'variables';

.wrapper {
  gap: 1.875rem;

  .confirmationCodeInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    .confirmationCodeInfoTitle {
      font-size: 1rem;
      margin-bottom: 0.625rem;
    }

    .confirmationCodeInfoMessage {
      p {
        opacity: 0.9;
        font-size: 0.875rem;
        font-weight: 300;
        margin: 0;
      }
    }
  }

  .quickAccSigningForm {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .code2faNotRequiredMsg {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      background-color: $success-background-color;
      color: $alert-text-color;
      padding: 0.9375rem;
      font-size: 0.875rem;
      border-radius: $border-radius;
    }

    .inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.25rem;
  flex-wrap: wrap;

  .button {
    &.danger {
      flex: 1;
      background-color: transparent;
      border: 2px solid $danger-color;
      color: $danger-color;
    }

    &.confirm {
      flex: 3;
      .loading {
        width: auto;
      }
    }
  }
}

@include xxl-breakpoint {
  .wrapper {
    .quickAccSigningForm {
      .inputsContainer {
        flex-direction: row;

        & > div {
          flex: 1;

          input {
            height: 3.125rem;
          }
        }
      }
    }
  }

  .buttons {
    gap: 0.625rem;

    .button {
      font-size: 1rem;
      height: 3.125rem;

      &.danger {
        flex: 1;
      }

      &.confirm {
        flex: 1;
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    text-align: center;

    .quickAccSigningForm {
      .inputsContainer {
        flex-direction: column;

        & > div {
          flex: unset;

          input {
            height: 3.75rem;
          }
        }
      }
    }
  }

  .buttons {
    flex-direction: column-reverse;
    gap: 0.625rem;
    width: 100%;

    .button {
      font-size: 0.875rem;
      width: 100%;
      height: 3.125rem;

      &.danger {
        flex: unset;
      }

      &.confirm {
        flex: unset;
      }
    }
  }
}
