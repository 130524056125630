@import 'variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  cursor: default;
  border: 1px solid $danger-color;
  border-radius: $border-radius;
  padding: 1.25rem;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $warning-color;

    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  .message {
    font-size: 1.125rem;
    font-weight: 500;
    color: $warning-color;
    word-break: break-word;
    text-align: center;
  }
}
