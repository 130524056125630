@import 'variables.scss';

.wrapper {
  .titleIcon {
    width: 2rem;
    height: 2rem;
  }
  .item {
    display: flex;
    align-items: stretch;
    height: 3.125rem;
    background-color: $c-mirage;
    padding: 0;
    
    button {
      outline: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }

    a, button {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      color: $c-titan-white;
      white-space: nowrap;
      font-weight: 400;
      padding-left: 1rem;
      padding-right: 1.375rem;
      width: 100%;

      .itemIcon {
        width: 1.875rem;
        height: 1.875rem;
      }
    }

    &:hover {
      background-color: $c-ebony-clay;
      cursor: pointer;
    }
  }

  &:not(.viewed) {
    .title {
      &::after {
        content: '';
        position: absolute;
        top: -0.25rem;
        right: -0.75rem;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 100%;
        background-color: $c-turquoise;
      }
    }
  }
}
