@import 'variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1.25rem;
  height: 3.75rem;
  border-radius: $border-radius;
  color: $c-titan-white;
  background-color: $c-mirage;

  &.disabled {
    opacity: 0.2;
  }

  &:not(.disabled) {
    .topUpLink {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .body {
    display: flex;
    align-items: center;
    flex: 1;

    .baseInfo {
      display: flex;
      align-items: center;
      flex: 1.5;

      .icon {
        position: relative;
        flex: 0 0 auto;
        @include flex-center-center;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: $border-radius;
        background-color: rgba($c-titan-white, 0.05);
        overflow: hidden;

        img {
          height: 1.5rem;
          width: 1.5rem;
          user-select: none;
        }
      }

      .name {
        font-size: 1.25rem;
        font-weight: 500;
        margin: 0;
        margin-left: 0.75rem;
      }
    }

    .balance {
      display: flex;
      align-items: center;
      flex: 2;

      .currency,
      .dollar {
        margin: 0;
        flex: 1;
      }

      .currency {
        display: flex;
        gap: 0.3125rem;
        font-size: 1rem;
      }

      .dollar {
        font-size: 0.875rem;
        color: $c-turquoise;
      }
    }
  }

  .topUpLink {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3125rem;
    height: 2.5rem;
    padding: 0 1.125rem;
    background-color: rgba($c-titan-white, 0.04);
    border-radius: $border-radius;
    font-size: 0.875rem;
    opacity: 1;
    transition: 0.3s ease-out opacity;
    cursor: not-allowed;
  }
}

@include sm-breakpoint {
  .wrapper {
    flex-direction: column;
    height: auto;
    gap: 0.625rem;
    padding: 0.9375rem;

    .body {
      width: 100%;
      justify-content: space-between;

      .baseInfo {
        .name {
          font-size: 1rem;
          margin-left: 0.5rem;
        }
      }

      .balance {
        flex-direction: column;
        align-items: flex-end;
        flex: unset;
      }
    }

    .topUpLink {
      width: 100%;
    }
  }
}
