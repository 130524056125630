@import 'variables.scss';

.wrapper {
  padding-bottom: 1.875rem;
  max-height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 0.625rem;
  }

  .rewardsDisabled {
    padding: 0.75rem;
    border: 1px solid $ambire-purple;
    border-radius: $border-radius;

    .rewardsDisabledIcon {
      width: 1.25rem;
      height: 1.25rem;
      color: $ambire-purple;
      display: inline;
      line-height: 1.75rem;
      vertical-align: middle;
    }
    .rewardsDisabledText {
      font-size: .875rem;
      display: inline;
      vertical-align: middle;
      font-weight: 500;
      line-height: 1.25rem;

      a {
        color: $ambire-purple;
        transition: $basic-transition;

        &:hover {
          opacity: .6;
        }
      }
    }
    .rewardsDisabledLink {
      display: block;
      width: fit-content;
      margin-left: auto;
      color: $ambire-purple;
      font-size: .875rem;
      font-weight: 600;
      text-decoration: underline;
      transition: $basic-transition;

      &:hover {
        opacity: .6;
      }
    }
  }

  .info {
    font-size: 0.75rem;
    text-align: center;

    a {
      color: $c-heliotrope;
    }
  }

  .item {
    border-radius: $border-radius;
    padding: 0.9375rem 1.25rem;
    display: flex;
    flex-direction: column;
    background: $c-mirage;

    .details {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      label {
        flex-grow: 1;
        font-weight: 500;
      }

      .balance {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-weight: 500;
        text-align: right;

        .loader {
          width: 1.5rem;
          height: 1.5rem;
        }

        .amount {
          display: flex;
          gap: 0.4375rem;
          font-size: 1rem;

          span {
            color: $c-turquoise;
          }

          .usd,
          .apy {
            font-size: 0.875rem;
          }

          .usd {
            span {
              color: $c-heliotrope;
            }
          }

          .apy {
            span {
              color: $tertiary-accent-color;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;

      button {
        height: 2.5rem;
        font-size: 0.8125rem;
        background-color: rgba(255, 255, 255, 0.1);
      }

      .fullWidthButton {
        width: 100%;
      }
    }
  }

  .info {
    margin-top: 0; // To keep in mind if badges visual margin is removed
    opacity: 0.8;
  }

  .rewardsBar {
    width: 100%;
    height: 0.625rem;
    padding: 0.125rem;
    border-radius: $border-radius;
    overflow: hidden;

    .rewardsBarProgress {
      border-radius: 0.875rem;
      height: 0.375rem;
    }
  }

  .mainRewardInfo {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .walletRewardsTitle {
      text-align: center;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
    }

    .walletRewardsStatusHolder {
      display: flex;
      flex-grow: 1;
      flex-basis: 50%;

      .walletRewardsStatus {
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.75rem;
        justify-content: flex-start;

        min-width: 8.125rem;

        .walletRewardsStatusInfo {
          text-align: center;
        }

        .walletRewardsStatusLogo {
          display: inline-flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          gap: 1rem;

          .walletRewardsStatusLogoImg {
            width: 4rem;
            height: 4rem;
            background: no-repeat 50% 50% url('../../../resources/icon.png');
            background-size: contain;
            margin: auto;
          }

          .walletRewardsStatusLogoText {
            text-align: center;
          }
        }
      }
    }

    .walletRewardsClaimableHolder {
      display: flex;
      justify-content: center;
      flex-basis: 50%;

      .actions {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 0.5rem;

        button {
          height: 2rem;
          font-size: 0.8125rem;
          background-color: rgba(255, 255, 255, 0.1);
        }

        .claimRewardsXWallet {
          text-transform: inherit !important;
        }

        .claimRewardsWithBurn {
          background-color: $danger-color;
        }
      }
    }
  }

  .buttons {
    button {
      flex: 0 1 100% !important;
    }
  }
}

@include lg-breakpoint {
  .wrapper .rewardsDisabled {
    .rewardsDisabledLink {
      margin-top: .5rem;
    }
  }
}