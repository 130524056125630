@import 'variables';

.wrapper {
  .panelBody {
    overflow: auto;
  }

  .listOfTransactions {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-bottom: 1.25rem;
  }
}

@include lg-breakpoint {
  .wrapper {
    .titleWrapper {
      flex-direction: column;

      .title {
        position: static;
        transform: none;
      }
    }
  }
}
