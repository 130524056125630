@import 'variables.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($c-ebony-clay, 0.8) 0% 0% no-repeat padding-box;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    min-height: 33.125rem;
    background-color: $c-ebony-clay;
    max-width: 50rem;
    padding-top: 0;
    padding: 1.25rem;
    border-radius: $border-radius;
    z-index: 5;
    box-shadow: 0 0 0.9375rem #00000040;
  }

  .content {
    text-align: center;
    padding-top: 2.5rem;

    .dangerAnimationWrapper {
      position: relative;
      .dangerAnimation {
        width: 7.75rem;
        height: 7.75rem;
        margin: 0 auto;
        margin-bottom: 2.5rem;
      }

      .dangerAnimationCircle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }
    }

    .warningTitle {
      font-size: 1.5rem;
      color: $danger-color;
      font-weight: 700;
      margin-bottom: 1.25rem;
    }

    .warningText {
      font-size: 1rem;
      line-height: 1.375rem;
      margin-bottom: 2.625rem;
    }
  }
  .buttons {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2.125rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.9375rem;

    .button:first-child {
      margin-bottom: 0.625rem;
    }

    .button:last-child {
      background-color: #161920;
    }
    .button {
      color: $c-titan-white;
      width: 100%;
      box-sizing: border-box;
      padding: 1.3125rem 0;
    }
  }
}
