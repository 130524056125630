@import 'variables.scss';

$txn-height: 3.375rem;
$extended-summary-height: 2.25rem;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  min-height: $txn-height;
  overflow: hidden;
  background-color: theme(background-primary);
  color: theme(text-primary);
  border-radius: $border-radius;
  user-select: none;
  transition: 0.3s ease-out;

  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0.625rem 1.25rem;
    margin: 0;
    cursor: pointer;

    .info {
      min-width: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 0.625rem;

      .summaryContainer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.25rem;

        .expandTxn {
          align-self: flex-start;
          min-width: 2.25rem;
          min-height: 2.25rem;
          @include flex-center-center;
          background-color: theme(background-icon);
          border-radius: $border-radius;

          .icon {
            transition: 0.2s ease-out transform;
            width: 0.625rem;
          }

          &.reversedChevron {
            .icon {
              transform: rotate(180deg);
            }
          }
        }

        .summary {
          width: 100%;
          word-break: break-word;
          font-size: 0.875rem;
          row-gap: 0.5rem;
        }
        .subSummary{
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        }
        .subSummary > *{
          margin-right: 0.625rem;
        }
      }

      .warning {
        font-size: 0.8125rem;
        font-weight: 300;
        padding: 0.625rem 0.9375rem;
        text-align: center;
        background-color: $danger-color;
        color: $c-titan-white;
        border-radius: $border-radius;
        margin: 0;
      }
    }

    .dissmissTxnWrapper {
      align-self: flex-start;
      height: 2.25rem;

      .dismissTxn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: $border-radius;
        background-color: theme(background-icon);
        transition: all 0.1s ease-in-out;
        opacity: 1;

        &.disabled {
          opacity: 0.2;
        }

        &:not(.disabled):hover {
          opacity: 0.6;
        }

        .icon {
          width: 1.375rem;
          height: 1.375rem;
        }
      }
    }
  }

  .advanced {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem 1.25rem;
    padding-right: 1.6875rem;
    padding-bottom: 0.8125rem;

    p {
      margin: 0;
      word-break: break-word;
      font-size: 0.8438rem;
      font-weight: 300;
      color: theme(text-secondary);
      user-select: text;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    position: relative;

    .heading {
      gap: 0.3125rem;
      padding: 0.625rem 0.9375rem;

      .dissmissTxnWrapper {
        .dismissTxn {
          width: 2rem;
          height: 2rem;

          .icon {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .info {
        gap: 0.625rem;

        .summaryContainer {
          gap: 0.625rem;

          .expandTxn {
            min-width: 2rem;
            min-height: 2rem;
            border-radius: $border-radius;
          }

          .summary {
            column-gap: 0.3125rem;
            row-gap: 0.125rem;
            font-size: 0.6875rem;
          }
        }

        .warning {
          font-size: 0.75rem;
          padding: 0.625rem;
          width: 100%;
        }
      }
    }
    .advanced {
      gap: 0.3125rem;
      padding-top: 0.3125rem;

      p {
        font-size: 0.75rem;
      }
    }
  }
}
