@import 'variables';

.wrapper {
  margin-bottom: 1.875rem;

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.625rem;
    margin-bottom: 2.6875rem;

    .title {
      margin: 0;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 400;
      color: $c-turquoise;
    }

    .network {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      p {
        margin: 0;
        font-size: 0.875rem;
        opacity: 0.9;

        &.name {
          text-transform: capitalize;
        }
      }

      .networkIcon {
        flex: 0 0 auto;
        @include flex-center-center;
        height: 2rem;
        width: 2rem;
        border-radius: $border-radius;
        background-color: $c-mirage;

        img {
          height: 1.5rem;
          width: 1.5rem;
          user-select: none;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    max-height: 13.125rem;
    margin-bottom: 1.25rem;
    padding-right: 1.25rem;
    gap: 0.625rem;
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-bottom: 1.875rem;

    .warningIcon {
      width: 1.5rem;
      height: 1.5rem;
      color: $c-ebony-clay;
    }

    .warningText {
      flex: 1;
      font-size: 0.875rem;
      margin: 0;
      color: $warning-color;

      span {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  .depositBtn {
    max-width: 25rem;
    width: 100%;
    margin: 0 auto;
  }
}

@include lg-breakpoint {
  .wrapper {
    .list {
      max-height: 26.875rem;
      padding-right: 0.75rem;
    }

    .depositBtn {
      font-size: 1rem;
      height: 3.125rem;
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    .titleWrapper {
      flex-direction: column;

      .title {
        font-size: 1.2rem;
      }
    }
    .warning {
      margin-bottom: 1.25rem;

      .warningText {
        font-size: 0.75rem;

        span {
          font-size: 0.875rem;
        }
      }
    }
  }
}
