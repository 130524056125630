@import 'variables';

.details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.9375rem;
  flex-wrap: wrap-reverse;

  .explorerLink {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    font-size: 0.75rem;
    color: $c-scampi;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin: 0;
    font-size: 0.75rem;

    &.error {
      color: $warning-color;
    }

    &.pending {
      color: $tertiary-accent-color;
    }

    &.confirmed {
      color: $c-turquoise;
    }

    .loading {
      width: auto;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    span {
      opacity: 0.8;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
