@import 'variables';

.wrapper {
  text-align: center;

  .fee,
  .time {
    font-size: 0.75rem;
    margin: 0;
  }

  .fee {
    color: $c-heliotrope;
    margin-bottom: 0.3125rem;
  }

  .time {
    color: $c-scampi;
  }
}
