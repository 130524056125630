@import 'variables.scss';

.wrapper {
  .content {
    .timer {
      justify-content: center;
      margin-bottom: 1.25rem;
    }
    .timerResetMsg {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      color: $danger-color;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1.875rem;

      h4 {
        margin: 0;
        margin-bottom: 0.9375rem;
        font-size: 1rem;
        font-weight: 400;
      }

      .qrCode {
        .imgWrapper {
          display: flex;
          justify-content: center;
          margin-bottom: 0.625rem;

          img {
            padding: 0.5rem;
            background-color: $c-white;
            width: 9.75rem;
            border-radius: $border-radius;
          }
        }
        .downloadQrCodeButton {
          display: block;
          width: fit-content;
          margin: 0 auto;
          color: $warning-color;
        }

        .imgMsg {
          text-align: center;

          div {
            color: $c-turquoise;
          }

          .clickHere {
            display: inline;
            cursor: pointer;
            color: rgba($c-titan-white, 0.8);
            font-size: 0.875rem;

            button,
            a {
              width: auto;
              background-color: transparent;
              border: none;
              cursor: pointer;
              color: $c-heliotrope;
              font-family: inherit;
            }
          }

          .clickHere:hover {
            opacity: 1;
          }
        }
      }

      .emailWrapper {
        .emailBody {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.625rem;

          & > button,
          div {
            flex: 0 1 50% !important;
          }
        }
      }

      button {
        width: 100%;
        flex: 0 1 100% !important;
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    max-height: 90vh;

    .content {
      form {
        gap: 1.25rem;

        .qrCode {
          .imgWrapper {
            margin-bottom: 0.625rem;

            img {
              width: 8.75rem;
            }
          }
        }

        .emailWrapper {
          .emailBody {
            justify-content: flex-start;
            flex-direction: column;

            & > button,
            div {
              flex: unset !important;
            }

            .emailInput {
              width: 100%;
            }
          }
        }
        .authenticatorWrapper {
          & > div {
            flex: unset !important;
          }
        }
      }
    }
  }
}
