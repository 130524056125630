@import 'variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 1.5625rem;
  background: $c-ebony-clay;
  border: 1px solid $c-fiord;
  border-radius: $border-radius;
  margin-bottom: 1.25rem;
}

@include sm-breakpoint {
  .wrapper {
    padding: 1rem;
  }
}
