@import 'variables.scss';

.wrapper {
  .customDappTitle {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .infoBtn {
      height: 2rem;
      color: $ambire-purple;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      .icon {
        font-size: 2rem;
        height: 2rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
  }
  .data {
    position: relative;
    padding: .9375rem;
    border: 1px solid $c-heliotrope;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 0.875rem;

    &.emptyUrl {
      border-color: $danger-color;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        background-color: $c-ebony-clay-light;
        opacity: .5;
        z-index: 2;
        cursor: not-allowed;
      }
    }
  }

  .dappInput {
    flex: 1;
    gap: 0.5rem;
    width: 100%;
    .textInputContainer {
      input {
        height: 2.5rem;
        font-size: 1rem;
        padding: 0.8em;
      }
    }
  }

  .iconInput {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    justify-content: space-between;
    align-items: flex-end;

    .textInput {
      flex-grow: 1;
    }

    .iconWrapper {
      border: 1px dashed $c-scampi;
      border-radius: $border-radius;
      width: 4.125rem;
      height: 4.125rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      svg {
        width: 2.875rem;
        height: 2.875rem;
      }
    }
  }

  .networks {
    .networksContainer {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      .networkTag {
        border: 2px solid $c-mirage;
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        background-size: 1rem 1rem;
        background-color: transparent;
        background-position: center;
        mask-repeat: no-repeat;
        border-radius: 2rem;
        filter: grayscale(100%);
        cursor: pointer;

        &.selected {
          filter: none;
          border: 2px solid $c-turquoise;
        }
      }
    }
  }

  .networks,
  .connectionRadios {
    > :first-child {
      margin-bottom: 0.5rem;
    }
  }

  .inputErr {
    margin-top: 0.25rem;
    color: $warning-color;
    font-size: 0.8em;
  }
}

@include sm-breakpoint {
  .wrapper {
    .networks {
      .networksContainer {
        .networkTag {
          height: 1.5rem;
          width: 1.5rem;
          background-size: 1.25rem 1.25rem;
        }
      }
    }
  }
}
