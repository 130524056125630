@import 'variables.scss';

.wrapper {
  padding-bottom: 0;

  .validationMsg {
    color: $danger-color;
  }

  .buySellBtnsWrapper {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    width: 30%;
    padding-bottom: 1.25rem;

    .button {
      padding: 0.625rem 2.1875rem;
      background-color: $c-mirage;
      color: $c-titan-white;
      cursor: pointer;
      border-radius: $border-radius;

      &.active {
        color: $c-titan-white;
        background-color: $c-heliotrope;
      }
    }
  }
  .inputCurrenciesWrapper {
    display: flex;
    background-color: $c-mirage;
    padding: 0.25rem 1.25rem;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
    border-radius: $border-radius;
    height: 5.9375rem;
    flex-direction: column;

    .currenciesRowWrapper {
      display: flex;
      justify-content: space-between;
      gap: 0.3125rem;
      align-items: center;

      .estimateInfoInput {
        flex: 1;
      }
    }
    .inputLabel {
      margin: 0;
      color: $c-scampi;
      font-size: 1rem;
    }

    input {
      height: auto;
      padding: 0;
      font-size: 1.25rem;
      color: $c-titan-white;
    }

    .amount {
      flex: 0 1 50%;

      &Input {
        & > div {
          border-radius: 0;
        }
        input {
          padding-bottom: 0.25rem;
        }
      }

      .loadingInput {
        opacity: 0.68;
      }
    }

    .currency {
      flex: 0 1 50%;

      .select {
        min-width: 2.8125rem;
        width: 100%;
        .selectInput {
          padding: 0;
          font-size: 1.25rem;
          gap: 0.625rem;
        }

        .selectIcon {
          background-color: $c-ebony-clay-light;
          padding: 0.5rem;
          border-radius: $border-radius;
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    .loadingWrapper {
      margin-top: 1.25rem;
      height: 2.5rem;
      width: 100%;
      background-color: $c-mirage;
      border-radius: $border-radius;
    }
  }

  .estimationInfoWrapper {
    margin-left: 0.9375rem;
    margin-bottom: 0.625rem;
    position: relative;
    margin-top: 0.625rem;
    display: flex;
    flex-direction: column;

    &:before {
      content: '';
      display: block;
      border-right: 2px solid #898dcb;
      height: 100%;
      position: absolute;
      z-index: -1;
      margin-left: 0.25rem;
    }
  }

  .extraFees,
  .estimationRate {
    position: relative;
    padding-left: 1.5625rem;
    margin-top: 0;
    margin-bottom: 0.75rem;

    &:before {
      content: '';
      background: #898dcb;
      border-radius: 50%;
      width: 0.625rem;
      height: 0.625rem;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -0.25rem;
      z-index: 1;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 300;

      span {
        font-size: 1rem;
      }
    }
  }
  .extraFees {
    margin-top: 0.8125rem;
    margin-bottom: 0.8125rem;
  }
  .button {
    width: 100%;
    margin-bottom: 7.5rem;
  }

  .poweredBy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    padding: 0.625rem 0;
    border-top: 1px solid #3e436b;

    &Label {
      font-size: 0.875rem;
      color: #898dcb;
    }

    &Logo {
      width: 9rem;
    }
  }
}
