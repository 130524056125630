@import 'variables.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
  padding: 1.5625rem 1.875rem 1.875rem;
  background-color: theme(background-terniary);
  border: 1px solid theme(border-secondary);
  border-radius: $border-radius;

  .title {
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-size: 1.5625rem;
    font-weight: 400;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5625rem;
    // Without setting this prop, a child auto-scrollable component (without certain height) is not growing correctly into the available space,
    // instead it gets 100% height, its scrollbars are not even shown and it overflows its parent height.
    // Please check how Transfer -> Address book list is working when toggling on/off this prop.
    // min-height set a proper height for the child components and works perfectly.
    // @credits: https://stackoverflow.com/a/68183297/4312466
    min-height: 0;
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    gap: 1rem;
    padding: 1.25rem;
  }
}
