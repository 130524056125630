@import 'variables';

.wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.875rem;
  text-align: center;
  padding-left: 1.25rem;

  p {
    margin: 0;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: $c-titan-white;
    fill: $c-titan-white;
  }

  .iconAndDate {
    flex: 2.5 0 0;
    display: flex;
    align-items: center;

    .gasTank {
      display: flex;
      flex: 1 0 0;

      svg {
        width: 2rem;
        height: 2rem;

        * [fill] {
          fill: $c-titan-white;
          color: $c-titan-white;
        }
      }
    }

    .date {
      flex: 1.5 0;
      font-weight: 300;
      text-align: left;
    }
  }

  .balanceAndLink {
    flex: 2.5 0 0;
    display: flex;
    align-items: center;

    .balance {
      display: flex;
      flex: 1.5 0;
      align-items: center;
      justify-content: left;
      font-weight: 300;
      gap: 0.625rem;

      .tokenIcon {
        position: relative;
        flex: 0 0 auto;
        @include flex-center-center;
        height: 2rem;
        width: 2rem;
        border-radius: $border-radius;
        background-color: rgba($c-titan-white, 0.05);
        overflow: hidden;
        margin-left: 0.75rem;

        img {
          height: 1.125rem;
          width: 1.125rem;
          user-select: none;
        }
      }

      .name {
        font-size: 0.875rem;
        font-weight: 300;
        margin: 0;
      }
    }
  }
}

@include lg-breakpoint {
  .wrapper {
    position: relative;
    flex-direction: column;
    gap: 0.625rem;
    padding-left: 0;
    margin-left: 1.25rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba($c-scampi, 0.2);

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 0;
      right: 0;
    }

    .iconAndDate,
    .balanceAndLink {
      width: 100%;
      flex: unset;
      justify-content: space-between;
    }

    .iconAndDate {
      .gasTank {
        display: none;
      }

      .date {
        text-align: left;
        font-size: 0.8rem;
        font-weight: 300;
        opacity: 0.7;
      }
    }

    .balanceAndLink {
      .balance {
        .tokenIcon {
          margin-left: 0;
        }
      }
    }
  }
}
