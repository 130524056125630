@import 'variables';

.wrapper {
  p {
    margin: 0;
    margin-bottom: 0.9375rem;
    font-weight: 300;
    opacity: 0.7;
  }

  .saveAlert {
    margin-bottom: 3.75rem;
  }
}

@include lg-breakpoint {
  .wrapper {
    p {
      margin-bottom: 0.625rem;
    }

    .saveAlert {
      margin-bottom: 1.875rem;
    }
  }
}

@include sm-breakpoint {
  .wrapper {
    text-align: center;

    p {
      font-size: 0.875rem;
    }
  }
}
