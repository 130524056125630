@import 'variables.scss';

.transactions {
  color: $c-titan-white;
  .paginationControls {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 0.9375rem;
    font-size: 0.9375rem;
    font-weight: 500;

    .pagination-current {
      span {
        opacity: 0.5;
      }
    }
    .paginationButton {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0 0.625rem;
    }
  }

  .panel {
    .title {
      gap: 0.625rem;
      font-size: 1.5625rem;
    }
  }

  .bundle {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 1.5625rem;
    background: $c-ebony-clay;
    border: 1px solid $c-fiord;
    border-radius: $border-radius;
    margin-bottom: 1.5625rem;

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 0.9375rem;

      button {
        color: $c-titan-white;
        border: 0;
        padding: 0.9375rem 1.875rem;

        &.cancel {
          color: $danger-color;
          border: 2px solid $danger-color;
        }

        &.speedUp {
          color: $c-turquoise;
          border: 2px solid $c-turquoise;
        }
      }
    }
  }

  .panel {
    gap: 0.625rem;
    padding: 0;
    background-color: transparent !important;

    .panelHeading {
      height: 4.6875rem;
      z-index: 47;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .buttons {
        display: flex;
        align-items: center;
        flex-direction: row;

        .title {
          margin-right: 1.875rem;

          svg {
            width: 2rem;
            height: 2rem;
          }

          button {
            all: unset;
            cursor: pointer;
            padding-bottom: 0.3125rem;
          }

          .inactive {
            color: $c-scampi;
            border-bottom: 3px solid $c-scampi;
            border-radius: 0.1875rem;
          }

          .active {
            color: $c-titan-white;
            border-bottom: 3px solid $c-turquoise;
            border-radius: 0.1875rem;
          }

          .activeIcon {
            fill: $c-turquoise;
          }

          .inactiveIcon {
            fill: $c-scampi;
          }
        }
      }
      .topPagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .content {
      gap: 1.5625rem;
    }
  }

  .waitingTransactions {
    .bundle {
      gap: 1.5625rem;

      .bundleList {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      }
    }
  }

  .pending {
    .bundlePreview {
      padding: 0;
      padding-bottom: 1.25rem;
    }
  }

  .title {
    display: flex;

    h2 {
      flex-grow: 1;
    }
  }

  .bottomPagination {
    display: flex;
    justify-content: flex-end;
    .paginationControls {
      margin-top: 0.625rem;
      margin-bottom: 1.5625rem;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .transactions {
    .panel {
      .content {
        gap: 0.9375rem;
      }

      .panelHeading {
        .buttons {
          .title {
            font-size: 1rem;
          }
        }
      }
    }

    .bundle {
      padding: 0.9375rem;
      padding-bottom: 1.5625rem;

      button {
        color: $c-titan-white;
        border: 0;
        padding: 1.25rem 2.5rem;
        font-size: 1rem;

        &.cancel {
          color: $danger-color;
          border: 2px solid $danger-color;
        }

        &.speedUp {
          color: $c-turquoise;
          border: 2px solid $c-turquoise;
        }
      }
    }

    .bundlePreview .details {
      gap: 1.5625rem;
    }

    .panel-heading {
      top: -1.25rem !important;
      height: auto !important;
      flex-direction: column;
      justify-content: center !important;
      gap: 0.625rem;
      padding: 1em 0;

      .title {
        align-self: flex-start;
      }

      .paginationControls {
        align-self: flex-end !important;
      }
    }
  }
}

@include sm-breakpoint {
  .transactions {
    .panel {
      .panelHeading {
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 3.125rem;
        .buttons {
          flex-direction: row;
          justify-content: center;
          margin-bottom: 1.875rem;

          .title {
            white-space: nowrap;
            margin-right: 0.625rem;

            .inactive {
              text-overflow: ellipsis;
              overflow: hidden;
              border-bottom: 2px solid $c-scampi;
              border-radius: 0.125rem;
            }

            .active {
              border-bottom: 2px solid $c-turquoise;
              border-radius: 0.125rem;
            }
          }
        }
        .topPagination {
          .paginationControls {
            align-self: center;
            margin-left: 0;
          }
        }
      }
      .signedMessages {
        overflow-wrap: break-word;

        .headerContainer {
          display: none;
        }

        .subContainer {
          .subContainerVisible {
            flex-direction: column;
            align-items: flex-start;
            .colExpand {
              display: none;
            }

            .dapp {
              width: 100%;
              .colExpandMobile {
                display: flex;
                margin-left: auto;
              }
            }

            .colSigtype {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: 0.75rem;
              width: 100%;

              .colSigtypeLabel {
                color: $c-scampi;
                display: flex;
              }
            }

            .colDate {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: 0.75rem;
              width: 100%;

              .colDateLabel {
                color: $c-scampi;
                display: flex;
              }
            }
          }
        }
      }
    }
    .bundle {
      .actions {
        flex-direction: column;
      }
    }
    .pending {
      .panelHeading {
        margin-bottom: 0;
      }
    }
    .bottomPagination {
      justify-content: center;
    }
  }
}

@include xs-breakpoint {
  .transactions {
    .panel {
      .panelHeading {
        .buttons {
          .title {
            font-size: 0.875rem;
            margin-right: 1.25rem;

            .inactive {
              &.txnBtn {
                width: auto;
              }

              &.msgBtn {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
