@import 'variables';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  width: 100%;
  background-color: $c-mirage;
  border-radius: $border-radius;
  margin-bottom: 1.25rem;

  &.smaller {
    height: 8.75rem;
  }
}

.wrapper {
  .label {
    display: block;
    padding-left: 1.25rem;
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
  }

  .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
}
