@import 'variables';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.3125rem;

  .addSigner {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $c-scampi;
    border-radius: $border-radius;
    max-width: 15rem;
    min-height: 20rem;
    width: 100%;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    user-select: none;

    &Body {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.3s ease-in-out all;
      color: $c-scampi;

      svg {
        width: 2rem;
        height: 2rem;
      }

      label {
        cursor: pointer;
        margin-top: 0.625rem;
        font-size: 1.25rem;
      }
    }

    &:hover {
      background-color: $c-mirage;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .wrapper {
    justify-content: center;
  }
}

@include sm-breakpoint {
  .wrapper {
    .addSigner {
      max-width: 17.5rem;
    }
  }
}
