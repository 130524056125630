@import 'variables.scss';

.passwordStrength {
  position: absolute;
  z-index: 9;
  top: 60px;
  right: -0.625rem;
  transform: translate(100%, -50%);
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.9375rem;
  border-radius: 0.75rem;
  background-color: theme(background-terniary);
  border: 1px solid theme(border-secondary);

  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in opacity, 0.2s ease-in visibility;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .check {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .checkIcon,
    .xIcon {
      width: 1.25rem;
    }

    .checkIcon {
      color: $success-color;
    }
    .xIcon {
      color: $danger-color;
    }

    .checkLabel {
      opacity: 0.8;
      font-size: 0.875rem;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 1278px) {
    top: 3.125rem;
    right: 1.25rem;
    transform: translate(0, 0);
  }

  @media screen and (max-width: 768px) {
    padding: 0.625rem;
    right: 0;

    .check {
      .checkIcon,
      .xIcon {
        width: 0.9375rem;
      }

      .checkLabel {
        font-size: 0.75rem;
      }
    }
  }
}
