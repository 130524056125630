@import 'variables.scss';

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.875rem;
  padding: 1.25rem 1.5625rem;
  padding-bottom: 1.5625rem;
  background: $c-ebony-clay;
  border: 1px solid $c-fiord;
  border-radius: $border-radius;
  max-height: 43.75rem;
  min-height: 28.125rem;
  max-width: 28.125rem;

  .amount-label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      text-align: right;
      opacity: 0.8;
      max-width: 9.375rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    padding: 0.9375rem 0;

    img {
      height: 2.1875rem;
    }
  }

  .unavailable {
    display: flex;
    justify-content: center;
    font-weight: 400;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column !important;
    gap: 0.9375rem;

    .details {
      display: flex;
      flex-direction: column;
      gap: 0.3125rem;
      list-style-type: none;
      padding: 0 0.3125rem;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        color: #e8e8e9;

        div {
          display: flex;
          align-items: center;
        }
      }
    }

    .warning-msg {
      background: mix($warning-color, $c-ebony-clay, 5%);
      border: 0.0625rem solid $warning-color;
      color: $warning-color;
      padding: 0.5rem;
      font-size: 0.875rem;
      border-radius: $border-radius;
    }

    .details-placeholder {
      height: 4.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      opacity: 0.5;

      div {
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 1em;
      font-weight: 500;
    }

    .info-message {
      display: flex;
      align-items: center;
      min-height: 6.375rem;
    }

    .separator {
      flex-grow: 1;
    }
  }
}

// Mobile
@include lg-breakpoint {
  .card {
    min-height: unset;
    max-height: unset;
    width: 100%;
    padding: 1.25rem;
  }
}
